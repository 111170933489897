import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LegalRoutingEnum } from '@features/legal/legal-routing.enum';
import { buildUrl } from '@wizbii/angular-utilities';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  instagramUrl: string;
  fbUrl: string;
  inUrl: string;
  cguUrl: string;
  policyUrl: string;
  homeUrl: string;
  legalUrl: string;
  featuresUrl: string;
  pricingUrl: string;

  constructor(@Inject(DOCUMENT) private readonly document: any) {
    this.homeUrl = '/';
    this.instagramUrl = 'https://www.instagram.com/atsii.pro/';
    this.fbUrl = 'https://www.facebook.com/atsii.pro/';
    this.inUrl = 'https://www.linkedin.com/company/atsii/ ';
    this.cguUrl = buildUrl(LegalRoutingEnum.cgu);
    this.policyUrl = buildUrl(LegalRoutingEnum.policy);
    this.legalUrl = buildUrl(LegalRoutingEnum.legal);
    this.featuresUrl = buildUrl(FeaturesRoutingEnum.Feature);
    this.pricingUrl = buildUrl(FeaturesRoutingEnum.Pricing);
  }

  openCookiesModal(): void {
    this.document.dispatchEvent(new CustomEvent<void>('WizbiiGdpr.toggleDialogVisibility'));
  }
}
