<p *ngIf="loading" class="visually-hidden">
  <ng-content></ng-content>
</p>

<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10">
  <path class="checkmark__path" fill="none" shape-rendering="geometricPrecision" d="M11.32.99L3.96 8.34.67 5.12" />
</svg>

<svg class="circle" viewBox="-3 -3 95 95" xmlns="http://www.w3.org/2000/svg">
  <circle class="circle__path" cx="45" cy="45" r="45" fill="none" shape-rendering="geometricPrecision" />
</svg>
