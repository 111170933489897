import { Company } from '@ats/models';

export class GetCompany {
  static readonly type = '[Company] Get';
  constructor(readonly id: string, readonly silence404 = true) {}
}

export class GetCompanySuccess {
  static readonly type = '[Company] Get Success';
  constructor(readonly company: Company) {}
}

export class SetAuthenticatedCompany {
  static readonly type = '[Company] Set authenticated companyId';
  constructor(readonly id: string) {}
}
