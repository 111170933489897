/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkmark-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./checkmark-loader.component";
var styles_CheckmarkLoaderComponent = [i0.styles];
var RenderType_CheckmarkLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckmarkLoaderComponent, data: {} });
export { RenderType_CheckmarkLoaderComponent as RenderType_CheckmarkLoaderComponent };
function View_CheckmarkLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "visually-hidden"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_CheckmarkLoaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckmarkLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "checkmark"], ["viewBox", "0 0 12 10"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:path", [["class", "checkmark__path"], ["d", "M11.32.99L3.96 8.34.67 5.12"], ["fill", "none"], ["shape-rendering", "geometricPrecision"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "circle"], ["viewBox", "-3 -3 95 95"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:circle", [["class", "circle__path"], ["cx", "45"], ["cy", "45"], ["fill", "none"], ["r", "45"], ["shape-rendering", "geometricPrecision"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CheckmarkLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-checkmark-loader", [], [[1, "data-loading", 0]], null, null, View_CheckmarkLoaderComponent_0, RenderType_CheckmarkLoaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.CheckmarkLoaderComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).loading; _ck(_v, 0, 0, currVal_0); }); }
var CheckmarkLoaderComponentNgFactory = i1.ɵccf("app-checkmark-loader", i3.CheckmarkLoaderComponent, View_CheckmarkLoaderComponent_Host_0, { loading: "loading" }, {}, ["*"]);
export { CheckmarkLoaderComponentNgFactory as CheckmarkLoaderComponentNgFactory };
