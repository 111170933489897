import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

interface ModalConfig {
  layered: boolean;
  type: string;
}

export interface Modal {
  onDismiss: Function;
  config: ModalConfig;
}

const defaultConfig: ModalConfig = { layered: false, type: 'contact' };

@Injectable()
export class ModalContactService {
  private readonly display = new ReplaySubject<string>();
  private modal: Modal;

  get display$(): Observable<string> {
    return this.display.asObservable();
  }

  get config() {
    return this.modal.config;
  }

  open(display = '', onDismiss: Function, config: ModalConfig = defaultConfig): () => void {
    this.modal = { onDismiss, config };
    this.display.next(display);

    return this.close.bind(this);
  }

  dismiss() {
    if (this.modal && this.modal.onDismiss) {
      this.modal.onDismiss();
    }
  }

  private close() {
    this.modal = undefined;
    this.display.next();
  }
}
