import { HttpErrorResponse } from '@angular/common/http';
import { JwtTokens } from '@wizbii/angular-utilities';

export abstract class SessionFailed {
  static readonly type;
  constructor(public error: HttpErrorResponse | Error) {}
}

export class Init {
  static readonly type = '[Session] Init';
  constructor(public tokens?: JwtTokens) {}
}

export class InitUser {
  static readonly type = '[Session] Init User';
  constructor(public id: string) {}
}

export class RefreshTokensStore {
  static readonly type = '[Session] Refresh Tokens Store';
  constructor(public tokens: JwtTokens) {}
}

export class Logout {
  static readonly type = '[Session] Logout';
}

export class SetTokens {
  static readonly type = '[Session] Set Tokens';
  constructor(public tokens: JwtTokens) {}
}
