import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { contactQueryParam } from '@commons/contact-modal/contact-modal.component';
import { ModalContactService } from '@core/services/modal-contact.service';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { UrlService } from '@core/services/url.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  private closeContactModal: () => void;

  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled() {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  // Initialized globaly to watch all router events
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(LOCALE_ID) private readonly localeId: string,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    readonly contactModalService: ModalContactService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly urlService: UrlService,
    _: RouterExtensionService // initialise globally to watch all router events,
  ) {
    if (isPlatformServer(platformId)) {
      return;
    }
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event) {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  /**
   * Disable hover on `touchstart` to cover browsers that do not support pointer events.
   * https://caniuse.com/#feat=pointer
   */
  @HostListener('touchstart')
  onTouchStart() {
    this.hoverEnabled = false;
  }

  @HostListener('mousedown')
  onMouseDown() {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown() {
    this.focusOutlineDisabled = false;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && this.document.documentElement.lang === '') {
      this.setLanguageInDom(this.localeId);
    }

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(({ contact }) => {
      if (!!contact && !this.closeContactModal) {
        if (contact === 'demo') {
          this.closeContactModal = this.contactModalService.open(contact, this.doCloseContactModal.bind(this), {
            layered: false,
            type: 'demo',
          });
        } else {
          this.closeContactModal = this.contactModalService.open(contact, this.doCloseContactModal.bind(this));
        }
      } else if (!contact && this.closeContactModal) {
        this.closeContactModal();
        this.closeContactModal = undefined;
      }
    });
  }

  private doCloseContactModal(): void {
    this.router.navigate([this.urlService.cleanUrl(this.router.url)], {
      queryParams: { [contactQueryParam]: undefined },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setLanguageInDom(language: string): void {
    this.document.documentElement.lang = language;
  }
}
