import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { InitGuard } from '@core/guards/init.guard';
import { MustHaveRecruiterGuard } from '@core/guards/must-have-recruiter.guard';
import { environment } from '@env/environment';
import { FeaturesRoutingModule } from '@features/features-routing.module';
import { NotFoundModule } from '@features/not-found/not-found.module';
import { HeadDescriptionModule, HeadTitleModule } from '@wizbii/seo-lib';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CompanyWebservice,
  CONTACT_API_CONFIG,
  ContactWebservice,
  RECRUITER_API_CONFIG,
  RecruiterWebservice,
} from '@wizbii/webservices';

@NgModule({
  imports: [CommonModule, FeaturesRoutingModule, NotFoundModule, HeadTitleModule, HeadDescriptionModule, TrackerModule],
  providers: [
    InitGuard,
    MustHaveRecruiterGuard,
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    AuthenticationWebservice,
    {
      provide: CONTACT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.contact,
      },
    },
    ContactWebservice,
    {
      provide: RECRUITER_API_CONFIG,
      useValue: {
        baseUrl: environment.api.recruiter,
      },
    },
    RecruiterWebservice,
    CompanyWebservice,
  ],
})
export class FeaturesModule {}
