import { environment } from '@env/environment';
var ɵ0 = {
    appId: environment.applicationId,
    baseUrl: environment.api.authentication,
}, ɵ1 = {
    appId: environment.applicationId,
    baseUrl: environment.api.contact,
}, ɵ2 = {
    baseUrl: environment.api.recruiter,
};
var FeaturesModule = /** @class */ (function () {
    function FeaturesModule() {
    }
    return FeaturesModule;
}());
export { FeaturesModule };
export { ɵ0, ɵ1, ɵ2 };
