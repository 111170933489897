import * as atob from 'atob';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@nguniversal/express-engine/tokens";
import * as i2 from "ngx-cookie-service";
var GlobalService = /** @class */ (function () {
    // tslint:disable:cognitive-complexity
    function GlobalService(request, cookieService) {
        this.cookieService = cookieService;
        if (request) {
            this.init(request.jwt || null, request.refreshToken || null);
        }
        else {
            var rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
            this.init(rawTokens && rawTokens['token'] ? rawTokens['token'] : null, rawTokens && rawTokens['refreshToken'] ? rawTokens['refreshToken'] : null);
        }
    }
    Object.defineProperty(GlobalService.prototype, "tokens", {
        get: function () {
            return this._tokens;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "userId", {
        get: function () {
            return this._userId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "roles", {
        get: function () {
            return this._roles;
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.parseJwt = function (jwt) {
        return JSON.parse(atob(jwt.split('.')[1]));
    };
    GlobalService.prototype.init = function (token, refreshToken) {
        this._tokens =
            token && refreshToken
                ? {
                    token: token,
                    refreshToken: refreshToken,
                }
                : null;
        this._userId = this._tokens ? this.parseJwt(this._tokens.token)['user-id'] : null;
        this._roles = this._tokens ? this.parseJwt(this._tokens.token)['roles'] : null;
    };
    GlobalService.TOKEN_KEY = 'ats-app_tokens';
    GlobalService.EXPIRY_KEY = 'ats-app_expiry';
    GlobalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalService_Factory() { return new GlobalService(i0.ɵɵinject(i1.REQUEST, 8), i0.ɵɵinject(i2.CookieService)); }, token: GlobalService, providedIn: "root" });
    return GlobalService;
}());
export { GlobalService };
