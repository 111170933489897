import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CheckmarkLoaderModule } from '@commons/checkmark-loader/checkmark-loader.module';
import { ContactModalComponent } from '@commons/contact-modal/contact-modal.component';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    CheckmarkLoaderModule,
    RouterModule,
    TrackerModule.forRoot(),
  ],
  declarations: [ContactModalComponent],
  exports: [ContactModalComponent],
})
export class ContactModalModule {}
