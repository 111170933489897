import { Recruiter } from '@ats/models';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SetAuthenticatedCompany } from '@store/companies/companies.actions';
import {
  GetRecruiterByUserId,
  GetRecruiterSuccess,
  HandleErrors,
  SetAuthenticatedRecruiter,
} from '@store/recruiters/recruiters.actions';
import { SessionState } from '@store/session/session.state';
import { RecruiterWebservice } from '@wizbii/webservices';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';

export class RecruitersStateModel {
  authenticatedRecruiterId: string;
  recruiters: Record<string, Recruiter>;

  error: any;
}

const defaultState: RecruitersStateModel = {
  authenticatedRecruiterId: null,
  recruiters: {},

  error: null,
};

export const recruiterRefusedText = 'Recruiter refused';

@State<RecruitersStateModel>({
  name: 'recruiters',
  defaults: defaultState,
})
export class RecruitersState {
  @Selector()
  static authenticatedRecruiter(state: RecruitersStateModel) {
    return state.recruiters[state.authenticatedRecruiterId];
  }

  @Selector()
  static authenticatedRecruiterId(state: RecruitersStateModel) {
    return state.authenticatedRecruiterId;
  }

  constructor(private readonly recruiterWebservice: RecruiterWebservice, private readonly store: Store) {}

  @Action(GetRecruiterByUserId)
  getRecruiterByUserId(ctx: StateContext<RecruitersStateModel>, { id }: GetRecruiterByUserId) {
    const { recruiters } = ctx.getState();

    if (!recruiters[id]) {
      return this.recruiterWebservice.getByUserId(id).pipe(
        switchMap(profile => ctx.dispatch(new GetRecruiterSuccess(profile))),
        catchError(error => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(GetRecruiterSuccess)
  getRecruiterSuccess(ctx: StateContext<RecruitersStateModel>, { recruiter }: GetRecruiterSuccess) {
    return ctx.setState(
      patch<RecruitersStateModel>({
        authenticatedRecruiterId: recruiter._id,
        recruiters: patch({
          [recruiter._id]: recruiter,
        }),
      })
    );
  }

  @Action(SetAuthenticatedRecruiter)
  setAuthenticatedRecruiter(ctx: StateContext<RecruitersStateModel>, { id }: SetAuthenticatedRecruiter) {
    return ctx.dispatch(new GetRecruiterByUserId(id)).pipe(
      switchMap(() => this.store.select(RecruitersState.authenticatedRecruiter)),
      filter(recruiter => !!recruiter),
      first(),
      switchMap(recruiter => ctx.dispatch(new SetAuthenticatedCompany(recruiter.companyId)))
    );
  }

  @Action(HandleErrors)
  handleErrors(ctx: StateContext<RecruitersStateModel>, { error }: HandleErrors) {
    // tslint:disable no-small-switch
    switch (error.status) {
      // The Job doesn't exist so => 404
      case 404: {
        if (error.error === recruiterRefusedText) {
          console.error(recruiterRefusedText);

          ctx.patchState({ error });
          return of();
        }

        console.error('Not found');

        ctx.patchState({ error });
        const hasTokens = !!this.store.selectSnapshot(SessionState.tokens);

        return hasTokens
          ? EMPTY
          : ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
      }

      default: {
        console.error(`Code ${error.status} => ${error.statusText}`);
        return ctx.patchState({ error });
      }
    }
    // tslint:enable no-small-switch
  }
}
