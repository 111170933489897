import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { ModalContactService } from '@core/services/modal-contact.service';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Lock, Unlock } from '@store/scroll-lock/scroll-lock.actions';
import { RecipientEmailEnum, toContact } from '@wizbii/models';
import { ContactWebservice } from '@wizbii/webservices';
import { Subject } from 'rxjs';
export var contactQueryParam = 'contact';
var ContactModalComponent = /** @class */ (function () {
    function ContactModalComponent(contactWebservice, store, route, formBuilder, cdr, eventTrackerService, modalContactService) {
        this.contactWebservice = contactWebservice;
        this.store = store;
        this.route = route;
        this.formBuilder = formBuilder;
        this.cdr = cdr;
        this.eventTrackerService = eventTrackerService;
        this.modalContactService = modalContactService;
        this.destroyed$ = new Subject();
        this.layered = false;
        this.lockScroll = false;
        this.closeButton = true;
        this.dismiss = new EventEmitter();
        this.loading = false;
        this.sent = false;
    }
    ContactModalComponent.prototype.ngOnInit = function () {
        this.store.dispatch(new Lock());
        this.contactForm = this.formBuilder.group({
            firstname: [
                this.route.snapshot.queryParams['firstname'] ? this.route.snapshot.queryParams['firstname'] : '',
                Validators.required,
            ],
            lastname: [
                this.route.snapshot.queryParams['lastname'] ? this.route.snapshot.queryParams['lastname'] : '',
                Validators.required,
            ],
            entreprise: [
                this.route.snapshot.queryParams['entreprise'] ? this.route.snapshot.queryParams['entreprise'] : '',
                Validators.required,
            ],
            job: [this.route.snapshot.queryParams['job'] ? this.route.snapshot.queryParams['job'] : '', Validators.required],
            email: [
                this.route.snapshot.queryParams['email'] ? this.route.snapshot.queryParams['email'] : '',
                [Validators.required, Validators.email],
            ],
            phone: [this.route.snapshot.queryParams['phone'] ? this.route.snapshot.queryParams['phone'] : ''],
            message: ['', Validators.required],
        });
    };
    Object.defineProperty(ContactModalComponent.prototype, "form", {
        get: function () {
            return this.contactForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ContactModalComponent.prototype.ngAfterViewInit = function () {
        this.modal.nativeElement.focus();
    };
    ContactModalComponent.prototype.submit = function (form) {
        var _this = this;
        if (this.contactForm.invalid) {
            this.eventTrackerService.send({
                eventCategory: 'Home',
                eventAction: 'Form Status',
                eventLabel: [
                    !form.get('firstname').valid ? 'firstname' : undefined,
                    !form.get('lastname').valid ? 'lastname' : undefined,
                    !form.get('entreprise').valid ? 'entreprise' : undefined,
                    !form.get('job').valid ? 'job' : undefined,
                    !form.get('email').valid ? 'email' : undefined,
                ]
                    .filter(function (error) { return !!error; })
                    .join(','),
            });
            return;
        }
        this.loading = true;
        this.sent = false;
        this.contactWebservice
            .send(toContact({
            appId: environment.applicationId,
            firstName: form.value.firstname,
            lastName: form.value.lastname,
            email: form.value.email,
            phone: form.value.phone,
            content: form.value.message,
            attributes: {
                entreprise: form.value.entreprise,
                job: form.value.job,
            },
            recipientEmailKey: RecipientEmailEnum.ContactAts,
            subject: (this.modalContactService.config.type === 'demo' ? 'Demande de demo' : 'Demande de contact') + " - " + form.value.firstname + " " + form.value.lastname,
        }))
            .subscribe(function () {
            _this.eventTrackerService.send({
                eventCategory: 'Home',
                eventAction: 'Form Status',
                eventLabel: 'SUCCESS',
            });
            _this.loading = false;
            _this.sent = true;
            _this.cdr.detectChanges();
        });
    };
    ContactModalComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new Unlock());
        this.destroyed$.next();
        this.destroyed$.complete();
    };
    ContactModalComponent.prototype.doEscape = function () {
        this.dismiss.emit();
    };
    return ContactModalComponent;
}());
export { ContactModalComponent };
