var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { GetRecruiterByUserId, GetRecruiterSuccess, HandleErrors, SetAuthenticatedRecruiter, } from '@store/recruiters/recruiters.actions';
import { SessionState } from '@store/session/session.state';
import { RecruiterWebservice } from '@wizbii/webservices';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
var RecruitersStateModel = /** @class */ (function () {
    function RecruitersStateModel() {
    }
    return RecruitersStateModel;
}());
export { RecruitersStateModel };
var defaultState = {
    authenticatedRecruiterId: null,
    recruiters: {},
    error: null,
};
export var recruiterRefusedText = 'Recruiter refused';
var RecruitersState = /** @class */ (function () {
    function RecruitersState(recruiterWebservice, store) {
        this.recruiterWebservice = recruiterWebservice;
        this.store = store;
    }
    RecruitersState_1 = RecruitersState;
    RecruitersState.authenticatedRecruiter = function (state) {
        return state.recruiters[state.authenticatedRecruiterId];
    };
    RecruitersState.authenticatedRecruiterId = function (state) {
        return state.authenticatedRecruiterId;
    };
    RecruitersState.prototype.getRecruiterByUserId = function (ctx, _a) {
        var id = _a.id;
        var recruiters = ctx.getState().recruiters;
        if (!recruiters[id]) {
            return this.recruiterWebservice.getByUserId(id).pipe(switchMap(function (profile) { return ctx.dispatch(new GetRecruiterSuccess(profile)); }), catchError(function (error) { return ctx.dispatch(new HandleErrors(error)); }));
        }
    };
    RecruitersState.prototype.getRecruiterSuccess = function (ctx, _a) {
        var _b;
        var recruiter = _a.recruiter;
        return ctx.setState(patch({
            authenticatedRecruiterId: recruiter._id,
            recruiters: patch((_b = {},
                _b[recruiter._id] = recruiter,
                _b)),
        }));
    };
    RecruitersState.prototype.setAuthenticatedRecruiter = function (ctx, _a) {
        var _this = this;
        var id = _a.id;
        return ctx.dispatch(new GetRecruiterByUserId(id)).pipe(switchMap(function () { return _this.store.select(RecruitersState_1.authenticatedRecruiter); }), filter(function (recruiter) { return !!recruiter; }), first(), switchMap(function (recruiter) { return ctx.dispatch(new SetAuthenticatedCompany(recruiter.companyId)); }));
    };
    RecruitersState.prototype.handleErrors = function (ctx, _a) {
        var error = _a.error;
        // tslint:disable no-small-switch
        switch (error.status) {
            // The Job doesn't exist so => 404
            case 404: {
                if (error.error === recruiterRefusedText) {
                    console.error(recruiterRefusedText);
                    ctx.patchState({ error: error });
                    return of();
                }
                console.error('Not found');
                ctx.patchState({ error: error });
                var hasTokens = !!this.store.selectSnapshot(SessionState.tokens);
                return hasTokens
                    ? EMPTY
                    : ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
            }
            default: {
                console.error("Code " + error.status + " => " + error.statusText);
                return ctx.patchState({ error: error });
            }
        }
        // tslint:enable no-small-switch
    };
    var RecruitersState_1;
    __decorate([
        Action(GetRecruiterByUserId),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetRecruiterByUserId]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "getRecruiterByUserId", null);
    __decorate([
        Action(GetRecruiterSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetRecruiterSuccess]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "getRecruiterSuccess", null);
    __decorate([
        Action(SetAuthenticatedRecruiter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetAuthenticatedRecruiter]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "setAuthenticatedRecruiter", null);
    __decorate([
        Action(HandleErrors),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, HandleErrors]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "handleErrors", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RecruitersStateModel]),
        __metadata("design:returntype", void 0)
    ], RecruitersState, "authenticatedRecruiter", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RecruitersStateModel]),
        __metadata("design:returntype", void 0)
    ], RecruitersState, "authenticatedRecruiterId", null);
    RecruitersState = RecruitersState_1 = __decorate([
        State({
            name: 'recruiters',
            defaults: defaultState,
        }),
        __metadata("design:paramtypes", [RecruiterWebservice, Store])
    ], RecruitersState);
    return RecruitersState;
}());
export { RecruitersState };
