import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@commons/footer/footer.component';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';

@NgModule({
  imports: [CommonModule, RouterModule, SvgIconModule, MatFormFieldModule, MatSelectModule, FormsModule, TrackerModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
