var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetCompany, GetCompanySuccess, SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { CompanyWebservice } from '@wizbii/webservices';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
var CompaniesStateModel = /** @class */ (function () {
    function CompaniesStateModel() {
    }
    return CompaniesStateModel;
}());
export { CompaniesStateModel };
var defaultState = {
    authenticatedCompanyId: null,
    companies: {},
    error: null,
};
var CompaniesState = /** @class */ (function () {
    function CompaniesState(companyWebservice) {
        this.companyWebservice = companyWebservice;
    }
    CompaniesState_1 = CompaniesState;
    CompaniesState.company = function (companyId) {
        return createSelector([CompaniesState_1], function (state) {
            return state.companies[companyId];
        });
    };
    CompaniesState.authenticatedCompany = function (state) {
        return state.companies[state.authenticatedCompanyId];
    };
    CompaniesState.prototype.getCompany = function (ctx, _a) {
        var id = _a.id, silence404 = _a.silence404;
        var companies = ctx.getState().companies;
        if (!companies[id]) {
            return this.companyWebservice.get(id).pipe(switchMap(function (company) { return ctx.dispatch(new GetCompanySuccess(company)); }), silence404 // ADR #006
                ? catchError(function (err) {
                    ErrorHandlerService.silence(err, err instanceof HttpErrorResponse && err.status === 404);
                    return throwError(err);
                })
                : tap(function () { }));
        }
    };
    CompaniesState.prototype.getCompanySuccess = function (ctx, _a) {
        var _b;
        var company = _a.company;
        return ctx.setState(patch({
            companies: patch((_b = {},
                _b[company._id] = company,
                _b)),
        }));
    };
    CompaniesState.prototype.setAuthenticatedCompany = function (ctx, _a) {
        var id = _a.id;
        ctx.patchState({ authenticatedCompanyId: id });
        return ctx.dispatch(new GetCompany(id, false));
    };
    var CompaniesState_1;
    __decorate([
        Action(GetCompany),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetCompany]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "getCompany", null);
    __decorate([
        Action(GetCompanySuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetCompanySuccess]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "getCompanySuccess", null);
    __decorate([
        Action(SetAuthenticatedCompany),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetAuthenticatedCompany]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "setAuthenticatedCompany", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [CompaniesStateModel]),
        __metadata("design:returntype", void 0)
    ], CompaniesState, "authenticatedCompany", null);
    CompaniesState = CompaniesState_1 = __decorate([
        State({
            name: 'companies',
            defaults: defaultState,
        }),
        __metadata("design:paramtypes", [CompanyWebservice])
    ], CompaniesState);
    return CompaniesState;
}());
export { CompaniesState };
