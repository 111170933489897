import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BurgerModule } from '@commons/burger/burger.module';
import { PopupModule } from '@commons/popup/popup.module';
import { ProfilePopupModule } from '@commons/profile-popup/profile-popup.module';
import { StickyMenuComponent } from '@commons/sticky-menu/sticky-menu.component';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SvgIconModule,
    ProfilePopupModule,
    PopupModule,
    TrackerModule.forRoot(),
    BurgerModule,
  ],
  declarations: [StickyMenuComponent],
  exports: [StickyMenuComponent],
})
export class StickyMenuModule {}
