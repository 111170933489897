<svg class="visually-hidden" xmlns="http://www.w3.org/2000/svg">
  <!-- ATSII logo -->
  <symbol id="icon-atsii" viewBox="0 0 80 28">
    <g fill="none" fill-rule="evenodd">
      <path
        fill="currentColor"
        d="M10.82 3.32L0 27.6h5.76l2.15-5.2H19.2l2.15 5.2h5.9L16.38 3.3h-5.56zm2.75 5.52l3.85 9.3h-7.7l3.85-9.3zM41.25 26.7c-.53.4-1.18.7-1.96.89-.77.2-1.59.3-2.44.3-2.22 0-3.94-.57-5.16-1.7-1.21-1.14-1.82-2.8-1.82-5v-7.67H27V9.36h2.88V4.8h5.41v4.55h4.65v4.16h-4.65v7.6c0 .78.2 1.4.61 1.82.4.43.98.64 1.72.64.85 0 1.58-.23 2.19-.7l1.45 3.82zM46.04 27.31a11.87 11.87 0 0 1-3.54-1.44L44.3 22c.84.53 1.84.96 3.02 1.3 1.18.33 2.34.5 3.47.5 2.3 0 3.44-.57 3.44-1.7 0-.53-.31-.91-.94-1.15-.62-.23-1.58-.42-2.88-.59-1.52-.23-2.79-.5-3.78-.8-1-.3-1.86-.83-2.58-1.59-.73-.76-1.1-1.85-1.1-3.26a5.1 5.1 0 0 1 1.03-3.14 6.59 6.59 0 0 1 2.98-2.13c1.3-.51 2.85-.77 4.63-.77 1.32 0 2.63.15 3.94.44 1.3.29 2.39.68 3.24 1.2l-1.8 3.84a10.77 10.77 0 0 0-5.38-1.38c-1.16 0-2.02.16-2.6.48-.58.33-.87.74-.87 1.25 0 .58.31.98.94 1.22.62.23 1.62.45 2.98.65 1.53.26 2.78.53 3.75.82.97.29 1.82.82 2.53 1.58a4.48 4.48 0 0 1 1.08 3.19c0 1.16-.35 2.19-1.04 3.09-.7.9-1.7 1.6-3.04 2.1-1.33.5-2.9.74-4.73.74-1.55 0-3.07-.19-4.55-.57"
      />
      <path
        fill="#8683FF"
        d="M73.62 8.63h5.41v19.2h-1.91a3.5 3.5 0 0 1-3.5-3.5V8.64zM79.6 3.38A3.21 3.21 0 0 0 76.33.23a3.21 3.21 0 0 0-3.27 3.15 3.21 3.21 0 0 0 3.27 3.15c1.8 0 3.27-1.4 3.27-3.15M63.35 8.63h5.41v19.2h-1.9a3.5 3.5 0 0 1-3.5-3.5V8.64zM69.33 3.38A3.21 3.21 0 0 0 66.06.23a3.21 3.21 0 0 0-3.26 3.15 3.21 3.21 0 0 0 3.26 3.15c1.8 0 3.27-1.4 3.27-3.15"
      />
    </g>
  </symbol>

  <symbol id="icon-caret" viewBox="0 0 5 8">
    <path
      fill="currentColor"
      d="M.86 7.96a.85.85 0 0 1-.6-.24.84.84 0 0 1 0-1.19L2.8 4 .27 1.47a.84.84 0 0 1 0-1.19.84.84 0 0 1 1.18 0l3.13 3.13c.15.16.24.36.24.59 0 .22-.1.43-.25.6L1.46 7.71a.87.87 0 0 1-.6.24z"
    />
  </symbol>

  <symbol id="icon-book" viewBox="0 0 18.33 19.18">
    <path
      d="M9.17 19.18a1.88 1.88 0 0 1-1-.32 9.22 9.22 0 0 0-6.92-1.37 1 1 0 0 1-1.21-1V1A1 1 0 0 1 1 0h2.82a10.65 10.65 0 0 1 2.52.3 1 1 0 1 1-.54 1.93A8.77 8.77 0 0 0 3.57 2H2v13.36a11.18 11.18 0 0 1 7.17 1.8 11.18 11.18 0 0 1 7.17-1.8V2.05a9.2 9.2 0 0 0-6.12 1.49v9.7a1 1 0 1 1-2 0V3.07a1 1 0 0 1 .39-.8 11.23 11.23 0 0 1 9-2 1 1 0 0 1 .78 1v15.24a1 1 0 0 1-1.21 1 9.22 9.22 0 0 0-6.92 1.37 1.88 1.88 0 0 1-1.09.3z"
    />
  </symbol>

  <symbol id="icon-computer" viewBox="0 0 23.19 17">
    <path
      d="M21.69 17H1.5a1.5 1.5 0 0 1-1.2-2.4l2-2.67a1 1 0 0 0 .2-.6V3a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-10a1 1 0 0 1 0-2h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1v8.33a3 3 0 0 1-.6 1.8L2.5 15h18.38l-.28-.55a1 1 0 1 1 1.79-.89l.61 1.27A1.5 1.5 0 0 1 21.69 17z"
    />
  </symbol>

  <symbol id="icon-check" viewBox="0 0 30 30">
    <path
      d="M20.4 10c-.33.01-.64.15-.87.38-1.98 2-4.09 4.24-6.08 6.27l-2.65-2.19a1.25 1.25 0 1 0-1.6 1.93l3.55 2.92c.5.4 1.23.37 1.68-.08 2.25-2.26 4.68-4.89 6.88-7.09a1.25 1.25 0 0 0-.92-2.14z"
    />
  </symbol>

  <symbol id="icon-check-circle" viewBox="0 0 26 26">
    <g fill="none" fill-rule="evenodd">
      <circle fill="currentColor" cx="13" cy="13" r="13" />
      <path
        d="M17.675 8.667a1.083 1.083 0 0 0-.745.327c-1.723 1.728-3.548 3.678-5.27 5.434l-2.302-1.896a1.084 1.084 0 1 0-1.376 1.676l3.069 2.528a1.083 1.083 0 0 0 1.456-.074c1.948-1.952 4.054-4.23 5.958-6.139a1.083 1.083 0 0 0-.79-1.856z"
        fill="#FFF"
      />
    </g>
  </symbol>

  <symbol id="icon-cross" viewBox="0 0 11 11">
    <path
      d="M1.09 1.037l7.92 7.92M8.966 1.08L1.133 8.914"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
    />
  </symbol>

  <symbol id="icon-cross-circle" viewBox="0 0 28 28">
    <g fill="none" fill-rule="evenodd">
      <ellipse fill="currentColor" cx="14" cy="13.846" rx="14" ry="13.846" />
      <path d="M9.89 9.737l7.92 7.92m-.044-7.877l-7.833 7.833" stroke="#FFF" stroke-linecap="round" stroke-width="2" />
    </g>
  </symbol>

  <symbol id="icon-key" viewBox="0 0 24 12.72">
    <path
      d="M17 12.72a2 2 0 0 1-2-2V10a1 1 0 0 1 2 0v.72l2.76-.72a1 1 0 0 1 .56 0l1.68.56V8a1 1 0 0 0-1-1h-9.09A6 6 0 0 1 6 12a6.08 6.08 0 0 1-6-6 6 6 0 0 1 9.6-4.8 1 1 0 1 1-1.2 1.6A4 4 0 0 0 2 6a4.05 4.05 0 0 0 4 4 4 4 0 0 0 3.95-3.33 2 2 0 0 1 2-1.67H21a3 3 0 0 1 3 3v2.61a2 2 0 0 1-2.63 1.9L20 12l-2.48.62a2 2 0 0 1-.52.1z"
    />
  </symbol>

  <symbol id="icon-menu" viewBox="0 0 20 18">
    <path
      d="M20 1a1 1 0 0 0-1-1H1a1 1 0 1 0 0 2h18a1 1 0 0 0 1-1zm0 8a1 1 0 0 0-1-1H1a1 1 0 1 0 0 2h18a1 1 0 0 0 1-1zm0 8a1 1 0 0 0-1-1H1a1 1 0 1 0 0 2h18a1 1 0 0 0 1-1z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </symbol>

  <symbol id="icon-network" viewBox="0 0 22.12 17.25">
    <path
      d="M18 9.41a1 1 0 0 1-.62-.22 10.13 10.13 0 0 0-12.68 0 1 1 0 1 1-1.23-1.56 12.13 12.13 0 0 1 15.18 0A1 1 0 0 1 18 9.41zm3.9-4.25a1 1 0 0 0-.16-1.41 17.07 17.07 0 0 0-21.37 0 1 1 0 1 0 1.26 1.57 15.07 15.07 0 0 1 18.87 0 1 1 0 0 0 1.41-.16zM7.83 13.07a5.15 5.15 0 0 1 6.46 0 1 1 0 0 0 1.25-1.56 7.18 7.18 0 0 0-9 0 1 1 0 1 0 1.25 1.56zm3.23 1.7A1.24 1.24 0 1 0 12.3 16a1.24 1.24 0 0 0-1.24-1.23z"
    />
  </symbol>

  <symbol id="icon-rocket" viewBox="0 0 20.88 20.88">
    <path
      d="M12.11 20.88a1.49 1.49 0 0 1-1.11-.43l-1-1.07A1 1 0 0 1 11.4 18l.71.71.35-.35a.5.5 0 0 0 .14-.36l.59-4.12a2 2 0 0 1 .57-1.13l2.49-2.49a8.94 8.94 0 0 0 2.64-6.36V2H17a8.94 8.94 0 0 0-6.36 2.64L8.1 7.13a2 2 0 0 1-1.1.56l-4.13.59a.5.5 0 0 0-.28.14l-.35.35.71.71A1 1 0 0 1 1.5 10.9L.44 9.84a1.5 1.5 0 0 1 0-2.12L1.15 7a2.51 2.51 0 0 1 1.41-.7l4.13-.59 2.49-2.49A10.93 10.93 0 0 1 17 0h2.93a1 1 0 0 1 1 1v2.93a10.93 10.93 0 0 1-3.22 7.78l-2.54 2.49-.59 4.13a2.51 2.51 0 0 1-.71 1.41l-.71.71a1.49 1.49 0 0 1-1.05.43zM7.16 18l1.41-1.41a1 1 0 0 0-1.41-1.41l-1.42 1.38A1 1 0 1 0 7.16 18zm-4.25-1.44l2.83-2.83a1 1 0 0 0-1.41-1.41L1.5 15.14a1 1 0 1 0 1.41 1.41zm12.73-7.07a3 3 0 0 0 0-4.24 1 1 0 0 0-1.41 1.41 1 1 0 0 1 0 1.42 1 1 0 0 1-1.41 0 1 1 0 0 1 0-1.42 1 1 0 0 0-1.42-1.42 3 3 0 1 0 4.24 4.24z"
    />
  </symbol>

  <symbol id="icon-sort" viewBox="0 0 24 24.01">
    <path
      d="M12 24a12 12 0 1 1 10.12-5.59 1 1 0 0 1-1.69-1.07 10 10 0 1 0-2.85 2.95A1 1 0 1 1 18.71 22 12 12 0 0 1 12 24zm2.49-8.12a1 1 0 0 0-1-1h-3a1 1 0 0 0 0 2h3a1 1 0 0 0 1.01-.99zm1.5-4a1 1 0 0 0-1-1H9a1 1 0 0 0 0 2h6a1 1 0 0 0 1-.99zm2-4a1 1 0 0 0-1-1H7a1 1 0 0 0 0 2h10a1 1 0 0 0 1-.99z"
    />
  </symbol>

  <!-- Experience icon set -->
  <symbol id="icon-exp-account" viewBox="0 0 18 24">
    <path
      d="M15 24H5a1 1 0 0 1 0-2h10a1 1 0 0 0 1-1v-4.15a1.63 1.63 0 0 0-.89-1.31 16.32 16.32 0 0 0-12.22 0A1.63 1.63 0 0 0 2 16.85V23a1 1 0 0 1-2 0v-6.15a3.59 3.59 0 0 1 2.14-3.17 18.31 18.31 0 0 1 13.72 0A3.59 3.59 0 0 1 18 16.85V21a3 3 0 0 1-3 3zM14 5a5 5 0 1 0-5 5 5 5 0 0 0 5-5zm-2 0a3 3 0 1 1-3-3 3 3 0 0 1 3 3z"
    />
  </symbol>

  <symbol id="icon-exp-cross" viewBox="0 0 18 18">
    <path
      d="M.3 17.7c-.4-.4-.4-1 0-1.4l16-16c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-16 16c-.4.4-1 .4-1.4 0zm6.4-11c-.4.4-1 .4-1.4 0l-5-5C-.1 1.3-.1.7.3.3s1-.4 1.4 0l5 5c.4.4.4 1 0 1.4z"
    />
    <path d="M17.7 17.7c-.4.4-1 .4-1.4 0L8.6 10c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l7.7 7.7c.4.4.4 1 0 1.4z" />
  </symbol>

  <symbol id="icon-exp-chevron-down" viewBox="0 0 14 8">
    <path
      d="m 6.2553191,7.7037037 c -0.3971631,-0.2962963 -0.3971631,-0.8888889 0,-1.2839506 L 12.411348,0.2962963 c 0.397163,-0.39506173 0.893617,-0.39506173 1.29078,0 0.397163,0.39506172 0.397163,0.8888889 0,1.2839506 L 7.5460993,7.7037037 c -0.3971631,0.3950617 -0.893617,0.3950617 -1.2907802,0 z M 5.1631206,5.2345679 c -0.3971632,0.3950617 -0.9929078,0.3950617 -1.2907802,0 L 0.29787234,1.6790124 c -0.39716312,-0.3950618 -0.39716312,-0.88888895 0,-1.28395067 C 0.69503546,0 1.1914894,0 1.5886525,0.39506173 L 5.1631206,3.9506173 c 0.3971631,0.3950617 0.3971631,0.8888889 0,1.2839506 z"
    />
  </symbol>

  <symbol id="icon-exp-lock" viewBox="0 0 18 21">
    <path
      d="M15 21H3a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3h8a1 1 0 0 0 1-1V5.17A3.12 3.12 0 0 0 9.3 2 3 3 0 0 0 6 5a1 1 0 0 1-2 0 5 5 0 0 1 5.49-5A5.13 5.13 0 0 1 14 5.17V7a3 3 0 0 1-3 3H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 1 1 2 0v9a3 3 0 0 1-3 3z"
    />
  </symbol>

  <symbol id="icon-exp-lock-is-open" viewBox="0 0 21 22">
    <path
      d="M15 22H3a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3h7V5.69A5.65 5.65 0 0 1 15 0a5.5 5.5 0 0 1 6 5.5 1 1 0 0 1-2 0A3.5 3.5 0 0 0 15.15 2 3.63 3.63 0 0 0 12 5.69V10a1 1 0 0 1-1 1H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8h-.94a1 1 0 0 1 0-2H17a1 1 0 0 1 1 1v9a3 3 0 0 1-3 3z"
    />
  </symbol>

  <symbol id="icon-exp-right" viewBox="0 0 24 16">
    <path
      d="M22.5 9H1a1 1 0 1 1 0-2h20.17l-6-5.25a1 1 0 0 1 1.32-1.5l7 6.12A1.5 1.5 0 0 1 22.5 9zm-.33-1.12zm-5.68 7.88l4-3.5a1 1 0 0 0-1.32-1.51l-4 3.5a1 1 0 0 0 1.32 1.51z"
    />
  </symbol>

  <symbol id="icon-exp-search-settings" viewBox="0 0 24 24">
    <path
      d="M12.73 24h-1.46A2.28 2.28 0 0 1 9 21.73a.25.25 0 0 0-.15-.24 10.12 10.12 0 0 1-1.32-.55.25.25 0 0 0-.28.06A2.28 2.28 0 0 1 4 21l-1-1a2.27 2.27 0 0 1 0-3.21.25.25 0 0 0 .06-.28 9.81 9.81 0 0 1-.55-1.32.25.25 0 0 0-.24-.19A2.28 2.28 0 0 1 0 12.73v-1.46A2.28 2.28 0 0 1 2.27 9a.25.25 0 0 0 .24-.15 9.92 9.92 0 0 1 .55-1.32.25.25 0 0 0-.06-.29A2.27 2.27 0 0 1 3 4l1-1a2.28 2.28 0 0 1 3.24 0 .25.25 0 0 0 .27.05 10.08 10.08 0 0 1 1.32-.55.25.25 0 0 0 .17-.23A2.28 2.28 0 0 1 11.27 0h1.45A2.28 2.28 0 0 1 15 2.27a.25.25 0 0 0 .15.24 10 10 0 0 1 1.32.55.25.25 0 0 0 .28-.06A2.27 2.27 0 0 1 20 3l1 1a2.28 2.28 0 0 1 0 3.21.25.25 0 0 0-.06.28 9.81 9.81 0 0 1 .55 1.32.25.25 0 0 0 .24.15A2.28 2.28 0 0 1 24 11.27v1.46A2.28 2.28 0 0 1 21.73 15a.25.25 0 0 0-.24.15l-.08.24a1 1 0 1 1-1.88-.67l.07-.21A2.24 2.24 0 0 1 21.73 13a.27.27 0 0 0 .27-.27v-1.46a.27.27 0 0 0-.27-.27 2.24 2.24 0 0 1-2.14-1.52 7.82 7.82 0 0 0-.44-1.06 2.24 2.24 0 0 1 .44-2.59.27.27 0 0 0 .08-.19.27.27 0 0 0-.08-.19l-1-1a.27.27 0 0 0-.38 0 2.24 2.24 0 0 1-2.59.44 8.08 8.08 0 0 0-1.06-.44A2.24 2.24 0 0 1 13 2.27a.27.27 0 0 0-.27-.27h-1.46a.27.27 0 0 0-.27.27 2.24 2.24 0 0 1-1.52 2.14 8.12 8.12 0 0 0-1.06.44 2.24 2.24 0 0 1-2.59-.44.27.27 0 0 0-.38 0l-1 1a.27.27 0 0 0 0 .39 2.24 2.24 0 0 1 .44 2.59 7.93 7.93 0 0 0-.44 1.06A2.24 2.24 0 0 1 2.27 11a.27.27 0 0 0-.27.27v1.46a.27.27 0 0 0 .27.27 2.24 2.24 0 0 1 2.14 1.52 7.82 7.82 0 0 0 .44 1.06 2.24 2.24 0 0 1-.44 2.59.27.27 0 0 0-.08.19.27.27 0 0 0 .08.19l1 1a.28.28 0 0 0 .39 0 2.24 2.24 0 0 1 2.6-.44 8.11 8.11 0 0 0 1.06.44A2.24 2.24 0 0 1 11 21.73a.27.27 0 0 0 .27.27h1.45a.27.27 0 0 0 .27-.27 2.24 2.24 0 0 1 1.52-2.14l.19-.07a1 1 0 0 1 .7 1.88l-.25.09a.25.25 0 0 0-.16.24A2.28 2.28 0 0 1 12.73 24zm8.43-2.82a1 1 0 0 0 0-1.41l-4.84-5a1 1 0 0 0-.72-.31 1 1 0 0 0-.72.31 4 4 0 0 1-3.79 1.15A4 4 0 1 1 16 12a1 1 0 0 0 2 0 6 6 0 1 0-2.44 4.83l4.18 4.32a1 1 0 0 0 1.41 0z"
    />
  </symbol>

  <!-- Social icons (share, profile, etc.) -->
  <symbol id="icon-insta" viewBox="0 0 20 20">
    <g fill="#currentColor" fill-rule="evenodd">
      <path
        d="M10 1.804c2.67 0 2.985.01 4.04.059.974.044 1.504.207 1.856.344.434.16.827.416 1.15.748.331.322.587.715.747 1.15.137.351.3.88.344 1.855.049 1.055.059 1.37.059 4.04s-.01 2.986-.059 4.04c-.044.974-.207 1.504-.344 1.856a3.31 3.31 0 0 1-1.897 1.897c-.352.137-.882.3-1.856.345-1.054.048-1.37.058-4.04.058s-2.986-.01-4.04-.058c-.974-.045-1.504-.208-1.856-.345a3.097 3.097 0 0 1-1.15-.747 3.097 3.097 0 0 1-.747-1.15c-.137-.352-.3-.882-.344-1.856-.049-1.054-.059-1.37-.059-4.04s.01-2.985.059-4.04c.044-.974.207-1.504.344-1.856.16-.434.416-.827.748-1.15a3.097 3.097 0 0 1 1.15-.747c.351-.137.88-.3 1.855-.344 1.055-.048 1.37-.059 4.04-.059zm0-1.8c-2.715 0-3.055.01-4.122.06-1.064.048-1.79.217-2.426.464a4.9 4.9 0 0 0-1.77 1.153 4.9 4.9 0 0 0-1.154 1.77C.28 4.089.112 4.815.063 5.879.015 6.944.003 7.285.003 10s.012 3.056.06 4.122c.05 1.064.218 1.79.465 2.426a4.9 4.9 0 0 0 1.153 1.77 4.9 4.9 0 0 0 1.77 1.154c.637.247 1.363.416 2.427.465 1.067.048 1.407.06 4.122.06s3.056-.012 4.122-.06c1.064-.049 1.79-.218 2.426-.465a5.112 5.112 0 0 0 2.924-2.924c.247-.636.416-1.362.465-2.426.048-1.067.06-1.407.06-4.122s-.012-3.056-.06-4.122c-.049-1.064-.218-1.79-.465-2.426a4.9 4.9 0 0 0-1.153-1.77A4.9 4.9 0 0 0 16.55.527C15.911.28 15.185.112 14.121.063 13.056.015 12.715.003 10 .003z"
        fill-rule="nonzero"
      />
      <path
        d="M10 4.866a5.134 5.134 0 1 0 0 10.268 5.134 5.134 0 0 0 0-10.268zm0 8.466a3.332 3.332 0 1 1 0-6.664 3.332 3.332 0 0 1 0 6.664z"
        fill-rule="nonzero"
      />
      <circle cx="15.336" cy="4.664" r="1.2" />
    </g>
  </symbol>
  <symbol id="icon-facebook" viewBox="0 0 8 17">
    <path
      fill-rule="evenodd"
      d="M1.8 16.79h2.91v-8h2.2l.42-2.5H4.71V4.46c0-.58.38-1.2.92-1.2h1.5V.77H5.3v.01c-2.86.1-3.44 1.76-3.5 3.5v2H.34v2.5H1.8v8z"
    />
  </symbol>

  <symbol id="icon-linkedin" viewBox="0 0 14 13">
    <path
      d="M14 13h-3.1V8.78c0-1.1-.48-1.86-1.54-1.86-.82 0-1.27.51-1.48 1-.08.18-.07.42-.07.67V13H4.75s.04-7.47 0-8.15H7.8v1.28c.18-.56 1.16-1.36 2.73-1.36 1.94 0 3.46 1.17 3.46 3.7V13zM1.65 3.81h-.02C.64 3.81 0 3.08 0 2.16 0 1.2.66.5 1.67.5c1 0 1.62.71 1.64 1.65a1.6 1.6 0 0 1-1.66 1.66zM.35 4.85h2.73V13H.35V4.85z"
    />
  </symbol>

  <symbol id="icon-play" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="12" fill="#EDECFF" fill-opacity="0.26"/>
    <path d="M17 10.1962C18.3333 10.966 18.3333 12.8905 17 13.6603L11 17.1244C9.66667 17.8942 8 16.9319 8 15.3923L8 8.4641C8 6.9245 9.66667 5.96225 11 6.73205L17 10.1962Z" fill="white"/>
  </symbol>
</svg>
