import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-checkmark-loader',
  templateUrl: './checkmark-loader.component.html',
  styleUrls: ['./checkmark-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckmarkLoaderComponent {
  @Input()
  @HostBinding('attr.data-loading')
  loading: boolean;
}
