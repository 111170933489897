<div class="nav-container" [class.nav-container--scrolled]="pos > 100 || windowWidth < 1150 || isLogged">
  <nav class="nav container" [class.nav--open]="menuOpen">
    <a [routerLink]="[homeUrl]" class="nav__logo" [style.color]="logoColorOverride" (click)="menuOpen = false">
      <svg-icon
        icon="atsii"
        label="Page d’accueil site de l'ATS"
        i18n-label
        width="6em"
        [height]="windowWidth < 931 ? '1.5em' : '2em'"
        appEventTracker
        eventCategory="Main Navigation"
        eventAction="Click Logo"
      ></svg-icon>
    </a>

    <ul class="nav__list" [class.nav__list--open]="menuOpen">
      <li class="nav-item">
        <a
          class="nav-item__link"
          appEventTracker
          eventCategory="Main Navigation"
          eventAction="Click Features"
          [routerLink]="['/', featuresRoutingEnum.Feature]"
          routerLinkActive="nav-item__link--active"
          i18n
          >Fonctionnalités</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-item__link"
          appEventTracker
          eventCategory="Main Navigation"
          eventAction="Click Pricing"
          [routerLink]="['/', featuresRoutingEnum.Pricing]"
          routerLinkActive="nav-item__link--active"
          i18n
          >Tarifs</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-item__link"
          appEventTracker
          eventCategory="Main Navigation"
          eventAction="Click Contact Us"
          routerLink="."
          i18n
          [queryParams]="{ contact: 'open' }"
          >Nous contacter</a
        >
      </li>
    </ul>

    <ng-container *ngIf="isLogged; else loggedOut">
      <ng-container *ngIf="recruiter">
        <ng-container *ngIf="company">
          <div class="nav__actions">
            <a
              class="nav__actions__redirect-app redirect-app"
              appEventTracker
              eventCategory="Main Navigation"
              eventAction="Click Go To App"
              target="_blank"
              rel="noopener"
              [href]="appUrl"
            >
              <ng-container i18n>Accéder à l'application</ng-container>
              <svg-icon class="redirect-app__icon" icon="caret" width="0.4375em"></svg-icon>
            </a>
            <span class="divider"></span>
            <app-profile-popup [recruiter]="recruiter" [company]="company"></app-profile-popup>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #loggedOut>
      <span class="nav__actions">
        <a class="nav__actions__link" [href]="appUrl + '/sign-in'" i18n>
          Me connecter
        </a>
        <a class="nav__actions__btn btn" [href]="appUrl + '/sign-up'" i18n>
          Inscription
        </a>
      </span>
    </ng-template>

    <app-burger class="nav__burger" (clickEvent)="toggleMenu($event)"></app-burger>
  </nav>
</div>
