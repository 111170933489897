import { HttpErrorResponse } from '@angular/common/http';
import { Recruiter } from '@ats/models';

export class GetRecruiterByUserId {
  static readonly type = '[Recruiter] Get by user Id';
  constructor(readonly id: string) {}
}

export class GetRecruiterSuccess {
  static readonly type = '[Recruiter] Get by user id success';
  constructor(readonly recruiter: Recruiter) {}
}

export class SetAuthenticatedRecruiter {
  static readonly type = '[Recruiter] Set authenticated recruiterId';
  constructor(readonly id: string) {}
}

export class HandleErrors {
  static readonly type = '[Recruiter] Handle Errors';
  constructor(public error: HttpErrorResponse) {}
}
