<footer class="main-footer">
  <div class="container">
    <div class="main-footer__row">
      <div class="list">
        <a [routerLink]="['/']">
          <svg-icon
            class="list__title--logo"
            icon="atsii"
            label="Page d’accueil Atsii"
            width="7em"
            height="2em"
          ></svg-icon>
        </a>
      </div>

      <div class="list">
        <h4 class="list__title" i18n>A propos</h4>

        <ul class="list__items">
          <li>
            <a
              [routerLink]="['/', policyUrl]"
              target="_blank"
              appEventTracker
              eventCategory="Footer"
              eventAction="Click Privacy Policy"
              i18n
            >
              Politique de confidentialité
            </a>
          </li>

          <li>
            <a
              [routerLink]="['/', cguUrl]"
              target="_blank"
              appEventTracker
              eventCategory="Footer"
              eventAction="Click CGU"
              i18n
              >CGU</a
            >
          </li>

          <li>
            <a
              [routerLink]="['/', legalUrl]"
              target="_blank"
              appEventTracker
              eventCategory="Footer"
              eventAction="Click Legals"
              i18n
            >
              Mentions légales
            </a>
          </li>

          <li>
            <a
              href="https://corpo.wizbii.com"
              rel="noopener"
              appEventTracker
              eventCategory="Footer"
              eventAction="Click About Us"
              target="_blank"
              i18n
            >
              Qui sommes-nous&nbsp;?
            </a>
          </li>

          <li>
            <button
              appEventTracker
              eventCategory="Footer"
              eventAction="Click Cookie Management"
              class="btn-clean cookie-btn"
              (click)="openCookiesModal()"
              i18n
            >
              Gestion des Cookies
            </button>
          </li>
        </ul>
      </div>

      <div class="list">
        <h4 class="list__title" i18n>Liens utiles</h4>

        <ul class="list__items">
          <li>
            <a
              appEventTracker
              eventCategory="Footer"
              eventAction="Click Features"
              [routerLink]="['/', featuresUrl]"
              i18n
              >Fonctionnalités</a
            >
          </li>

          <li>
            <a appEventTracker eventCategory="Footer" eventAction="Click Pricing" [routerLink]="['/', pricingUrl]" i18n
              >Tarifs</a
            >
          </li>

          <li>
            <a
              appEventTracker
              eventCategory="Footer"
              eventAction="Click Contact Us"
              routerLink="."
              [queryParams]="{ contact: 'open' }"
              i18n
            >
              Nous contacter
            </a>
          </li>
        </ul>
      </div>

      <div class="list">
        <div class="list__row">
          <h4 class="list__title" i18n>Nous suivre</h4>
          <ul class="list__items social">
            <li>
              <a
                appEventTracker
                eventCategory="Footer"
                eventAction="Click Instagram"
                [href]="instagramUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="social__icon"
              >
                <svg-icon icon="insta" width="1.1em" label="instagram"></svg-icon>
              </a>
            </li>

            <li>
              <a
                appEventTracker
                eventCategory="Footer"
                eventAction="Click Facebook"
                [href]="fbUrl"
                target="_blank"
                rel="noopener"
                class="social__icon"
              >
                <svg-icon icon="facebook" width="1.1em" label="facebook"></svg-icon>
              </a>
            </li>

            <li>
              <a
                appEventTracker
                eventCategory="Footer"
                eventAction="Click Linkedin"
                [href]="inUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="social__icon"
              >
                <svg-icon icon="linkedin" width="1em" label="linkedin"></svg-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
