import { Injectable } from '@angular/core';
import { EventTrackerInterface } from '@commons/tracker/event-tracker/event-tracker.interface';
import { trackEvent } from '@wizbii/tracking';

@Injectable()
export class EventTrackerService {
  send(event: EventTrackerInterface): void {
    trackEvent(event.eventCategory, event.eventAction, event.eventLabel, event.eventValue as any);
  }
}
