import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Logout, RefreshTokensStore, SetTokens } from '@store/session/session.actions';
import { SessionState } from '@store/session/session.state';
import { JwtServiceInterface, JwtTokens } from '@wizbii/angular-utilities';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: (string | RegExp)[] = [
    // All auth routes exept : `${environment.api.authentication}/v1/user/change-email`,
    `${environment.api.authentication}/v1/user/overview`,
    `${environment.api.authentication}/v1/jwt/refresh`,

    environment.api.googleStorage,
  ];

  constructor(private readonly store: Store, private readonly authService: AuthenticationWebservice) {}

  getTokens(): Observable<JwtTokens> {
    return this.store.selectOnce(SessionState.tokens);
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.authService
      .refreshToken(tokens)
      .pipe(switchMap(newTokens => this.store.dispatch(new RefreshTokensStore(newTokens)).pipe(map(() => newTokens))));
  }

  saveLocalTokens(tokens: JwtTokens): void {
    this.store.dispatch(new SetTokens(tokens));
  }
}
