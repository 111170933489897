import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { JwtTokens } from '@wizbii/angular-utilities';
import * as atob from 'atob';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  static TOKEN_KEY = 'ats-app_tokens';
  static EXPIRY_KEY = 'ats-app_expiry';

  private _tokens: JwtTokens;
  private _userId: string;
  private _roles: string[];

  get tokens(): JwtTokens {
    return this._tokens;
  }

  get userId(): string {
    return this._userId;
  }

  get roles(): string[] {
    return this._roles;
  }

  // tslint:disable:cognitive-complexity
  constructor(@Optional() @Inject(REQUEST) request: any, private readonly cookieService: CookieService) {
    if (request) {
      this.init(request.jwt || null, request.refreshToken || null);
    } else {
      const rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
      this.init(
        rawTokens && rawTokens['token'] ? rawTokens['token'] : null,
        rawTokens && rawTokens['refreshToken'] ? rawTokens['refreshToken'] : null
      );
    }
  }

  private parseJwt(jwt: string) {
    return JSON.parse(atob(jwt.split('.')[1]));
  }

  init(token?: string, refreshToken?: string): void {
    this._tokens =
      token && refreshToken
        ? {
            token,
            refreshToken,
          }
        : null;
    this._userId = this._tokens ? this.parseJwt(this._tokens.token)['user-id'] : null;
    this._roles = this._tokens ? this.parseJwt(this._tokens.token)['roles'] : null;
  }
}
