import { Routes } from '@angular/router';
import { InitGuard } from '@core/guards/init.guard';
import { MustHaveRecruiterGuard } from '@core/guards/must-have-recruiter.guard';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./feature/feature.module.ngfactory").then(function (m) { return m.FeatureModuleNgFactory; }); }, ɵ2 = function () { return import("./pricing/pricing.module.ngfactory").then(function (m) { return m.PricingModuleNgFactory; }); }, ɵ3 = function () { return import("./legal/legal.module.ngfactory").then(function (m) { return m.LegalModuleNgFactory; }); }, ɵ4 = { unhandled404: true };
var routes = [
    {
        path: FeaturesRoutingEnum.Home,
        pathMatch: 'full',
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ0,
    },
    {
        path: FeaturesRoutingEnum.Feature,
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ1,
    },
    {
        path: FeaturesRoutingEnum.Pricing,
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ2,
    },
    {
        path: '',
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ3,
    },
    {
        path: FeaturesRoutingEnum.NotFound,
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        component: NotFoundComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
        data: ɵ4,
    },
];
var FeaturesRoutingModule = /** @class */ (function () {
    function FeaturesRoutingModule() {
    }
    return FeaturesRoutingModule;
}());
export { FeaturesRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
