var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { atsAppDomain } from '@env/environment';
import { Store } from '@ngxs/store';
import { RecruitersState } from '@store/recruiters/recruiters.state';
import { SessionState } from '@store/session/session.state';
import { combineLatest } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
var MustHaveRecruiterGuard = /** @class */ (function () {
    function MustHaveRecruiterGuard(store, errorHandlerService, window) {
        this.store = store;
        this.errorHandlerService = errorHandlerService;
        this.window = window;
    }
    MustHaveRecruiterGuard.prototype.canActivate = function () {
        var _this = this;
        return this.store.select(SessionState.isInitialized).pipe(filter(function (isInitialized) { return isInitialized; }), first(), switchMap(function () {
            return combineLatest([
                _this.store.selectOnce(RecruitersState.authenticatedRecruiterId),
                _this.store.selectOnce(SessionState.tokens),
            ]);
        }), map(function (_a) {
            var _b = __read(_a, 2), hasRecruiter = _b[0], tokens = _b[1];
            if (!hasRecruiter && !!tokens) {
                _this.errorHandlerService.warn('Finish your onbording first');
                _this.window.location.assign("https://" + atsAppDomain + "/wizbii/sign-in");
                return false;
            }
            return true;
        }));
    };
    return MustHaveRecruiterGuard;
}());
export { MustHaveRecruiterGuard };
