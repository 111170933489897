import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BurgerComponent } from '@commons/burger/burger.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [BurgerComponent],
  exports: [BurgerComponent],
})
export class BurgerModule {}
