var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
var EventTrackerDirective = /** @class */ (function () {
    function EventTrackerDirective(eventWebservice) {
        this.eventWebservice = eventWebservice;
        this.model = {
            eventCategory: undefined,
            eventAction: undefined,
            eventLabel: undefined,
            eventValue: undefined,
            algolia: undefined,
        };
    }
    Object.defineProperty(EventTrackerDirective.prototype, "eventCategory", {
        set: function (eventCategory) {
            this.model.eventCategory = eventCategory;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventTrackerDirective.prototype, "eventAction", {
        set: function (eventAction) {
            this.model.eventAction = eventAction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventTrackerDirective.prototype, "eventLabel", {
        set: function (eventLabel) {
            this.model.eventLabel = eventLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventTrackerDirective.prototype, "eventValue", {
        set: function (eventValue) {
            this.model.eventValue = eventValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventTrackerDirective.prototype, "algoliaTrackingData", {
        set: function (algolia) {
            this.model.algolia = algolia;
        },
        enumerable: true,
        configurable: true
    });
    EventTrackerDirective.prototype.doClick = function () {
        this.eventWebservice.send(__assign({}, this.model, { algolia: this.model.algolia && this.model.algolia.queryId ? this.model.algolia : undefined }));
    };
    return EventTrackerDirective;
}());
export { EventTrackerDirective };
