import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { contactQueryParam } from '@commons/contact-modal/contact-modal.component';
import { ModalContactService } from '@core/services/modal-contact.service';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { UrlService } from '@core/services/url.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var MainComponent = /** @class */ (function () {
    // Initialized globaly to watch all router events
    function MainComponent(document, localeId, platformId, contactModalService, route, router, urlService, _ // initialise globally to watch all router events,
    ) {
        this.document = document;
        this.localeId = localeId;
        this.platformId = platformId;
        this.contactModalService = contactModalService;
        this.route = route;
        this.router = router;
        this.urlService = urlService;
        this.destroy$ = new Subject();
        /**
         * Use class `hover-on` in CSS as follows:
         * `:host-context(.hover-on) .link:hover { ... }`
         */
        this.hoverEnabled = true;
        /**
         * Class `focus-off` disables all outlines automatically.
         */
        this.focusOutlineDisabled = false;
        if (isPlatformServer(platformId)) {
            return;
        }
    }
    Object.defineProperty(MainComponent.prototype, "hoverDisabled", {
        get: function () {
            return !this.hoverEnabled;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Enable hover if "mouse" pointer event is detected; disable it otherwise.
     * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
     */
    MainComponent.prototype.onPointerEnter = function (event) {
        var evt = event; // https://github.com/kulshekhar/ts-jest/issues/1035
        this.hoverEnabled = evt.pointerType === 'mouse';
    };
    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    MainComponent.prototype.onTouchStart = function () {
        this.hoverEnabled = false;
    };
    MainComponent.prototype.onMouseDown = function () {
        this.focusOutlineDisabled = true;
    };
    MainComponent.prototype.onTabKeyDown = function () {
        this.focusOutlineDisabled = false;
    };
    MainComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId) && this.document.documentElement.lang === '') {
            this.setLanguageInDom(this.localeId);
        }
        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var contact = _a.contact;
            if (!!contact && !_this.closeContactModal) {
                if (contact === 'demo') {
                    _this.closeContactModal = _this.contactModalService.open(contact, _this.doCloseContactModal.bind(_this), {
                        layered: false,
                        type: 'demo',
                    });
                }
                else {
                    _this.closeContactModal = _this.contactModalService.open(contact, _this.doCloseContactModal.bind(_this));
                }
            }
            else if (!contact && _this.closeContactModal) {
                _this.closeContactModal();
                _this.closeContactModal = undefined;
            }
        });
    };
    MainComponent.prototype.doCloseContactModal = function () {
        var _a;
        this.router.navigate([this.urlService.cleanUrl(this.router.url)], {
            queryParams: (_a = {}, _a[contactQueryParam] = undefined, _a),
            queryParamsHandling: 'merge',
        });
    };
    MainComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    MainComponent.prototype.setLanguageInDom = function (language) {
        this.document.documentElement.lang = language;
    };
    return MainComponent;
}());
export { MainComponent };
