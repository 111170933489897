import { version } from '@env/version';

let env: any = {};

if (typeof window === 'object') {
  env = window['env'] || {};
} else if (typeof global === 'object') {
  env = global['ANGULAR_ENV'] || {};
}

const platform: string = env.PLATFORM || 'prod';
export const wizbiiDomain = env.WIZBII_DOMAIN || 'wizbii.com';
export const atsDomain = env.ATS_DOMAIN || 'atsii.fr';
export const atsAppDomain = `app.${atsDomain}`;
export const assetsDomain = `https://www.${atsDomain}/assets`;
const atsPublicDomain = `www.${atsDomain}`;

export const environment = {
  applicationId: 'ats-app',
  version,
  wizbiiDomain,
  atsDomain,
  atsPublicDomain,
  bugsnagId: '7b01c776d5d8fc3c6eb06955d93066f6',
  bugsnagSsrId: 'TODO',
  prod: platform === 'prod',
  qa3: platform === 'qa3',
  qa4: platform === 'qa4',
  dev: platform === 'dev',
  apiPlatform: platform,
  name: platform,
  api: {
    account: `https://account.api.${wizbiiDomain}`,
    authentication: `https://auth.${wizbiiDomain}`,
    candidate: `https://candidate.api.${atsDomain}`,
    consent: `https://consent.${wizbiiDomain}`,
    contact: `https://contact.api.${wizbiiDomain}`,
    content: `https://content-api.${wizbiiDomain}`,
    file: `https://file.${wizbiiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${wizbiiDomain}`,
    images: `https://images.${wizbiiDomain}`,
    job: `https://job.api.${atsDomain}`,
    media: `https://m-api.${wizbiiDomain}`,
    place: `https://place.${wizbiiDomain}`,
    recruiter: `https://recruiter.api.${atsDomain}`,
    wizbiiAnalytiks: `https://a.${atsDomain}`,
    monitor: `https://monitor.api.${wizbiiDomain}`,
  },
  algolia: {
    applicationId: env.ALGOLIA_APP_ID || 'testing1G17FKGF4S',
    apiKey: env.ALGOLIA_API_KEY || '097ca7bbd7c3e8a0a4a3104d90aa0aef',
  },
  wizbiiAnalytiksUserId: env.WIZBII_ANALYTICS_UAI || 'UA-22851031-38',
  googleAnalyticsUserId: env.GOOGLE_ANALYTICS_UAI || 'UA-22851031-38',
  facebookPixelId: env.FACEBOOK_PIXEL_ID || '1696909753928687',
  wizbiiFiles: env.WIZBII_FILES_GCS_BUCKET || 'wizbii-files',
  gdprWidgetUrl: `https://storage.googleapis.com/wizbii/consent/${platform}/widget/bundle.js`,
  hotjar: {
    id: env.HOTJAR_ID || '1258167',
    version: '6',
  },
  facebookPixel: {
    appId: env.FACEBOOK_PIXEL_ID || '607776016412150',
    version: '2.0',
  },
};
