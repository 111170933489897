<div
  *ngIf="!layered"
  class="backdrop"
  (click)="dismiss.emit()"
  appEventTracker
  eventCategory="Home"
  eventAction="Click Close Form"
></div>

<div #contactModal class="contact-modal" [class.contact-modal--centered]="!layered" tabindex="-1" role="dialog">
  <button type="button" class="btn-clean contact-modal__close" (click)="dismiss.emit()">
    <svg-icon class="icon-close" icon="exp-cross" width="1em" height="1em"></svg-icon>
  </button>

  <perfect-scrollbar class="contact-modal-ps" [disabled]="lockScroll">
    <section class="contact-modal__inner layout-contact">
      <header class="layout-contact__header">
        <h2 class="layout-contact__title" i18n *ngIf="!loading && !sent && modalContactService.config.type !== 'demo'">Contactez-nous&nbsp;!</h2>
        <p class="layout-contact__sub-title" i18n *ngIf="!loading && !sent">
          Nos équipes, vous répondront dans les plus brefs délais&nbsp;!
        </p>
      </header>

      <form
        *ngIf="!loading && !sent"
        [formGroup]="contactForm"
        (ngSubmit)="submit(contactForm)"
        class="layout-contact__form form"
        method="post"
      >
        <div class="form__group">
          <mat-form-field
            appearance="outline"
            class="form__input"
            [hideRequiredMarker]="true"
            [ngClass]="{
              'form__input--is-invalid': contactForm.touched && form.firstname.errors,
              'form__input--is-valid': contactForm.touched && !form.firstname.errors
            }"
          >
            <mat-label i18n>Prénom</mat-label>
            <input matInput type="text" name="firstname" formControlName="firstname" class="form-control" required />
            <mat-error *ngIf="contactForm.touched && form.firstname.errors?.required" i18n>
              Votre prénom est requis
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__group">
          <mat-form-field
            appearance="outline"
            class="form__input input"
            [hideRequiredMarker]="true"
            [ngClass]="{
              'form__input--is-invalid': contactForm.touched && form.lastname.errors,
              'form__input--is-valid': contactForm.touched && !form.lastname.errors
            }"
          >
            <mat-label i18n>Nom</mat-label>

            <input matInput type="text" name="lastname" formControlName="lastname" class="form-control" required />
            <mat-error *ngIf="contactForm.touched && form.lastname.errors?.required" i18n>
              Votre nom est requis
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__group">
          <mat-form-field
            appearance="outline"
            class="form__input"
            [hideRequiredMarker]="true"
            [ngClass]="{
              'form__input--is-invalid': contactForm.touched && form.email.errors,
              'form__input--is-valid': contactForm.touched && !form.email.errors
            }"
          >
            <mat-label i18n>Adresse email</mat-label>
            <input matInput type="email" name="email" formControlName="email" class="form-control" required />
            <mat-error *ngIf="contactForm.touched && form.email.errors?.required" i18n>
              Votre adresse email est requise
            </mat-error>

            <mat-error *ngIf="contactForm.touched && form.email.errors?.email && !form.email.errors?.required" i18n>
              Votre email est invalide
            </mat-error>

            <svg-icon
              *ngIf="!form.email.errors && form.email.touched"
              class="icon icon--valid"
              icon="exp-check"
              width=".875em"
              matSuffix
            ></svg-icon>

            <svg-icon
              *ngIf="form.email.errors && form.email.touched"
              class="icon icon--invalid"
              icon="exp-cross"
              width=".875em"
              matSuffix
            ></svg-icon>
          </mat-form-field>
        </div>

        <div class="form__group">
          <mat-form-field appearance="outline" class="form__input" [hideRequiredMarker]="true">
            <mat-label i18n>Téléphone</mat-label>
            <input matInput type="tel" name="phone" formControlName="phone" class="form-control" />
          </mat-form-field>
        </div>

        <div class="form__group form__group">
          <mat-form-field
            appearance="outline"
            class="form__input"
            [hideRequiredMarker]="true"
            [ngClass]="{
              'form__input--is-invalid': contactForm.touched && form.entreprise.errors,
              'form__input--is-valid': contactForm.touched && !form.entreprise.errors
            }"
          >
            <mat-label i18n>Entreprise</mat-label>

            <input matInput type="text" name="entreprise" formControlName="entreprise" class="form-control" required />

            <mat-error *ngIf="contactForm.touched && form.entreprise.errors?.required" i18n>
              Votre entreprise est requise
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__group form__group">
          <mat-form-field
            appearance="outline"
            class="form__input"
            [hideRequiredMarker]="true"
            [ngClass]="{
              'form__input--is-invalid': contactForm.touched && form.job.errors,
              'form__input--is-valid': contactForm.touched && !form.job.errors
            }"
          >
            <mat-label i18n>Poste occupé</mat-label>

            <input matInput type="text" name="job" formControlName="job" class="form-control" required />

            <mat-error *ngIf="contactForm.touched && form.job.errors?.required" i18n>
              Votre poste est requis
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__group form__group--full">
          <mat-form-field
            appearance="outline"
            class="form__input"
            [hideRequiredMarker]="true"
            [ngClass]="{
              'form__input--is-invalid': contactForm.touched && form.message.errors,
              'form__input--is-valid': contactForm.touched && !form.message.errors
            }"
          >
            <textarea
              matInput
              placeholder="Votre Message..."
              name="message"
              formControlName="message"
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="16"
              required
            ></textarea>

            <mat-error [@slideUp] *ngIf="contactForm.touched && form.message.errors" class="form__textarea__error" i18n>
              Vous devez écrire un message
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__group form__group--full">
          <button class="contact-modal__button" type="submit" i18n>Envoyer le message</button>
        </div>
      </form>

      <div *ngIf="loading || sent" class="layout-contact__validation">
        <div class="layout-contact__validation__container">
          <app-checkmark-loader class="layout-contact__validation__loader" [loading]="loading">
            Envoi en cours...
          </app-checkmark-loader>

          <ng-container *ngIf="!loading || sent">
            <div class="layout-contact__validation__text success" i18n>
              <h2 class="success__title" i18n>Merci&nbsp;!</h2>
              <p class="success__sub-title" i18n>
                Nous avons bien reçu votre message. <br />
                Nos équipes, vous répondront dans les plus brefs délais&nbsp;!
              </p>
            </div>

            <button type="button" class="contact-modal__button" (click)="dismiss.emit()" i18n>
              Terminer
            </button>
          </ng-container>
        </div>
      </div>
    </section>
  </perfect-scrollbar>
</div>
