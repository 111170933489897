import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  contactModalCloseTransition,
  contactModalOpenTransition,
  slideUp,
} from '@commons/contact-modal/contact-modal.animation';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { ModalContactService } from '@core/services/modal-contact.service';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Lock, Unlock } from '@store/scroll-lock/scroll-lock.actions';
import { RecipientEmailEnum, toContact } from '@wizbii/models';
import { ContactWebservice } from '@wizbii/webservices';
import { Subject } from 'rxjs';

export const contactQueryParam = 'contact';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
  animations: [contactModalOpenTransition, contactModalCloseTransition, slideUp],
  host: {
    '[@contactModalOpenTransition]': '',
    '[@contactModalCloseTransition]': '',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  @Input() layered = false;
  @Input() lockScroll = false;
  @Input() closeButton = true;

  @Output() dismiss = new EventEmitter<void>();

  @ViewChild('contactModal', { static: true }) modal: ElementRef;

  contactForm: FormGroup;

  loading = false;
  sent = false;

  constructor(
    private readonly contactWebservice: ContactWebservice,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly eventTrackerService: EventTrackerService,
    readonly modalContactService: ModalContactService
  ) {}

  ngOnInit() {
    this.store.dispatch(new Lock());

    this.contactForm = this.formBuilder.group({
      firstname: [
        this.route.snapshot.queryParams['firstname'] ? this.route.snapshot.queryParams['firstname'] : '',
        Validators.required,
      ],
      lastname: [
        this.route.snapshot.queryParams['lastname'] ? this.route.snapshot.queryParams['lastname'] : '',
        Validators.required,
      ],
      entreprise: [
        this.route.snapshot.queryParams['entreprise'] ? this.route.snapshot.queryParams['entreprise'] : '',
        Validators.required,
      ],
      job: [this.route.snapshot.queryParams['job'] ? this.route.snapshot.queryParams['job'] : '', Validators.required],
      email: [
        this.route.snapshot.queryParams['email'] ? this.route.snapshot.queryParams['email'] : '',
        [Validators.required, Validators.email],
      ],
      phone: [this.route.snapshot.queryParams['phone'] ? this.route.snapshot.queryParams['phone'] : ''],
      message: ['', Validators.required],
    });
  }

  get form(): {
    [key: string]: AbstractControl;
  } {
    return this.contactForm.controls;
  }

  ngAfterViewInit() {
    this.modal.nativeElement.focus();
  }

  submit(form: FormGroup) {
    if (this.contactForm.invalid) {
      this.eventTrackerService.send({
        eventCategory: 'Home',
        eventAction: 'Form Status',
        eventLabel: [
          !form.get('firstname').valid ? 'firstname' : undefined,
          !form.get('lastname').valid ? 'lastname' : undefined,
          !form.get('entreprise').valid ? 'entreprise' : undefined,
          !form.get('job').valid ? 'job' : undefined,
          !form.get('email').valid ? 'email' : undefined,
        ]
          .filter(error => !!error)
          .join(','),
      });
      return;
    }

    this.loading = true;
    this.sent = false;

    this.contactWebservice
      .send(
        toContact({
          appId: environment.applicationId,
          firstName: form.value.firstname,
          lastName: form.value.lastname,
          email: form.value.email,
          phone: form.value.phone,
          content: form.value.message,
          attributes: {
            entreprise: form.value.entreprise,
            job: form.value.job,
          },
          recipientEmailKey: RecipientEmailEnum.ContactAts,
          subject: `${this.modalContactService.config.type === 'demo' ? 'Demande de demo' : 'Demande de contact'} - ${
            form.value.firstname
          } ${form.value.lastname}`,
        })
      )
      .subscribe(() => {
        this.eventTrackerService.send({
          eventCategory: 'Home',
          eventAction: 'Form Status',
          eventLabel: 'SUCCESS',
        });
        this.loading = false;
        this.sent = true;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.store.dispatch(new Unlock());
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('keydown.escape')
  doEscape() {
    this.dismiss.emit();
  }
}
