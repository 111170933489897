var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { isPlatformBrowser } from '@angular/common';
import { GlobalService } from '@core/services/global.service';
import { Action, Selector, State } from '@ngxs/store';
import { SetAuthenticatedRecruiter } from '@store/recruiters/recruiters.actions';
import { Init, InitUser, Logout, RefreshTokensStore, SetTokens } from '@store/session/session.actions';
import { DataStorageService, deserializeJwt } from '@wizbii/angular-utilities';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
export var SessionStateEnum;
(function (SessionStateEnum) {
    SessionStateEnum["Init"] = "INIT";
    SessionStateEnum["Logged"] = "LOGGED";
    SessionStateEnum["Recovered"] = "RECOVERED";
    SessionStateEnum["NotLogged"] = "NOT_LOGGED";
})(SessionStateEnum || (SessionStateEnum = {}));
var SessionStateModel = /** @class */ (function () {
    function SessionStateModel() {
    }
    return SessionStateModel;
}());
export { SessionStateModel };
var defaultState = {
    tokens: null,
    loading: false,
    state: SessionStateEnum.Init,
    error: null,
    errorMessage: null,
    user: null,
};
var SessionState = /** @class */ (function () {
    function SessionState(dataStorageService, authService, cookieService, globalService, platformId, document) {
        this.dataStorageService = dataStorageService;
        this.authService = authService;
        this.cookieService = cookieService;
        this.globalService = globalService;
        this.platformId = platformId;
        this.document = document;
    }
    // tslint:disable:no-identical-functions
    SessionState.tokens = function (state) {
        return state.tokens;
    };
    SessionState.isInitialized = function (state) {
        return state.state !== SessionStateEnum.Init;
    };
    SessionState.isLogged = function (state) {
        return state.state === SessionStateEnum.Logged;
    };
    SessionState.prototype.init = function (ctx, _a) {
        var tokens = _a.tokens;
        var realTokens = tokens ? tokens : this.readTokens();
        if (!!realTokens) {
            this.globalService.init(realTokens.token, realTokens.refreshToken);
            ctx.patchState({ tokens: realTokens });
            var userId = deserializeJwt(realTokens.token)['user-id'];
            return ctx.dispatch([new InitUser(userId), new SetAuthenticatedRecruiter(userId)]).pipe(switchMap(function () { return of(ctx.patchState({ state: SessionStateEnum.Logged })); }), catchError(function () { return of(ctx.patchState({ state: SessionStateEnum.NotLogged })); }));
        }
        return ctx.patchState({ state: SessionStateEnum.NotLogged });
    };
    SessionState.prototype.initUser = function (ctx, _a) {
        var id = _a.id;
        return this.authService.getUserOverview(id).pipe(switchMap(function (user) { return of(ctx.patchState({ user: user })); }));
    };
    SessionState.prototype.refreshTokensStore = function (ctx, action) {
        var tokens = action.tokens;
        return ctx.patchState({
            tokens: tokens,
        });
    };
    SessionState.prototype.logout = function (ctx) {
        this.forgetTokens();
        if (isPlatformBrowser(this.platformId)) {
            this.document.location = this.document.location.origin;
        }
        return ctx.setState(defaultState);
    };
    SessionState.prototype.setTokens = function (ctx, action) {
        var tokens = action.tokens;
        this.writeTokens(tokens);
        return ctx.patchState({
            tokens: tokens,
        });
    };
    SessionState.prototype.readTokens = function () {
        var rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
        return !!rawTokens ? rawTokens : null;
    };
    SessionState.prototype.writeTokens = function (tokens) {
        var cookieDomain = this.getCookieDomain();
        var expiryExists = this.cookieService.check(GlobalService.EXPIRY_KEY);
        var msIn390Days = 1000 * 3600 * 24 * 390;
        var expiry = expiryExists
            ? new Date(this.cookieService.get(GlobalService.EXPIRY_KEY))
            : new Date(Date.now() + msIn390Days);
        if (!expiryExists) {
            this.cookieService.set(GlobalService.EXPIRY_KEY, expiry.getTime().toString(), expiry, '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
        }
        this.cookieService.set(GlobalService.TOKEN_KEY, JSON.stringify(tokens), expiry, '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
    };
    SessionState.prototype.forgetTokens = function () {
        var cookieDomain = this.getCookieDomain();
        this.dataStorageService.remove(GlobalService.TOKEN_KEY);
        this.cookieService.set(GlobalService.TOKEN_KEY, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
        this.cookieService.set(GlobalService.EXPIRY_KEY, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
    };
    SessionState.prototype.getCookieDomain = function () {
        var cookieSubDomain = __spread([''], this.document.location.hostname.split('.').slice(-2)).join('.');
        return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
    };
    __decorate([
        Action(Init),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Init]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "init", null);
    __decorate([
        Action(InitUser),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, InitUser]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "initUser", null);
    __decorate([
        Action(RefreshTokensStore),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RefreshTokensStore]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "refreshTokensStore", null);
    __decorate([
        Action(Logout),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "logout", null);
    __decorate([
        Action(SetTokens),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetTokens]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "setTokens", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "tokens", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "isInitialized", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "isLogged", null);
    SessionState = __decorate([
        State({
            name: 'session',
            defaults: defaultState,
        }),
        __metadata("design:paramtypes", [DataStorageService,
            AuthenticationWebservice,
            CookieService,
            GlobalService,
            Object, Object])
    ], SessionState);
    return SessionState;
}());
export { SessionState };
