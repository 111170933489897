import { environment } from '@env/environment';
import {
  createGoogleAnalyticsConfig,
  createHotjarConfig,
  createWizbiiAnalyticsConfig,
  TrackingConfig,
} from '@wizbii/tracking';

export const trackingConfig: TrackingConfig = {
  informationGroupKeys: ['technical'],
  uuidSetterDomain: environment.api.wizbiiAnalytiks,
  consentWidget: {
    version: !environment.prod ? 'staging' : '',
    apiDomain: environment.atsDomain,
    wizbiiApiDomain: environment.wizbiiDomain,
    enableTermsModal: false,
    locale: 'fr',
    productId: environment.applicationId,
    productLabel: 'ATS Public',
    defaultValue: false,
    labels: {},
    legalUrlBanner: `https://${environment.atsPublicDomain}/privacy-policy`,
  },
  trackers: {
    analytics_wizbii: createWizbiiAnalyticsConfig(environment.wizbiiAnalytiksUserId, environment.api.wizbiiAnalytiks),
    analytics_google: createGoogleAnalyticsConfig(environment.googleAnalyticsUserId),
    analytics_hotjar: createHotjarConfig({
      hjid: environment.hotjar.id,
      hjsv: environment.hotjar.version,
    }),
  },
};
