import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BurgerComponent {
  @Output() clickEvent = new EventEmitter<boolean>();

  open = false;

  toggle() {
    this.open = !this.open;
    this.clickEvent.emit(this.open);
  }
}
