import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckmarkLoaderComponent } from '@commons/checkmark-loader/checkmark-loader.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CheckmarkLoaderComponent],
  exports: [CheckmarkLoaderComponent],
})
export class CheckmarkLoaderModule {}
