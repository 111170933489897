/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./burger.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./burger.component";
var styles_BurgerComponent = [i0.styles];
var RenderType_BurgerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BurgerComponent, data: {} });
export { RenderType_BurgerComponent as RenderType_BurgerComponent };
export function View_BurgerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn-clean burger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, ":svg:svg", [["class", "menu burger__item"], ["height", "3.5em"], ["viewBox", "0 0 100 100"], ["width", "3.5em"]], [[2, "menu--active", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["class", "line menu__top"], ["d", "m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:path", [["class", "line menu__middle"], ["d", "m 30,50 h 40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["class", "line menu__bottom"], ["d", "m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.open; _ck(_v, 1, 0, currVal_0); }); }
export function View_BurgerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-burger", [], null, null, null, View_BurgerComponent_0, RenderType_BurgerComponent)), i1.ɵdid(1, 49152, null, 0, i2.BurgerComponent, [], null, null)], null, null); }
var BurgerComponentNgFactory = i1.ɵccf("app-burger", i2.BurgerComponent, View_BurgerComponent_Host_0, {}, { clickEvent: "clickEvent" }, []);
export { BurgerComponentNgFactory as BurgerComponentNgFactory };
