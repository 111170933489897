import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from '@commons/footer/footer.module';
import { StickyMenuModule } from '@commons/sticky-menu/sticky-menu.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { NotFoundComponent } from '@features/not-found/not-found.component';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, SvgIconModule, RouterModule, StickyMenuModule, FooterModule],
})
export class NotFoundModule {}
