/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../commons/contact-modal/contact-modal.component.ngfactory";
import * as i3 from "../../../commons/contact-modal/contact-modal.component";
import * as i4 from "@wizbii/webservices";
import * as i5 from "@ngxs/store";
import * as i6 from "@angular/router";
import * as i7 from "@angular/forms";
import * as i8 from "../../../commons/tracker/event-tracker/event-tracker.service";
import * as i9 from "../../services/modal-contact.service";
import * as i10 from "../sprite/sprite.component.ngfactory";
import * as i11 from "../sprite/sprite.component";
import * as i12 from "@angular/common";
import * as i13 from "./main.component";
import * as i14 from "../../services/url.service";
import * as i15 from "../../services/router-extension.service";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
function View_MainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-modal", [], [[40, "@contactModalOpenTransition", 0], [40, "@contactModalCloseTransition", 0]], [[null, "dismiss"], [null, "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).doEscape() !== false);
        ad = (pd_0 && ad);
    } if (("dismiss" === en)) {
        var pd_1 = (_co.contactModalService.dismiss() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ContactModalComponent_0, i2.RenderType_ContactModalComponent)), i1.ɵdid(1, 4440064, null, 0, i3.ContactModalComponent, [i4.ContactWebservice, i5.Store, i6.ActivatedRoute, i7.FormBuilder, i1.ChangeDetectorRef, i8.EventTrackerService, i9.ModalContactService], { layered: [0, "layered"] }, { dismiss: "dismiss" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.contactModalService.config.layered; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MainComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-sprite", [], null, null, null, i10.View_SpriteComponent_0, i10.RenderType_SpriteComponent)), i1.ɵdid(3, 49152, null, 0, i11.SpriteComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MainComponent_1)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.contactModalService.display$)); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], [[2, "hover-on", null], [2, "hover-off", null], [2, "focus-off", null]], [[null, "pointerenter"], [null, "touchstart"], [null, "mousedown"], [null, "keydown.Tab"]], function (_v, en, $event) { var ad = true; if (("pointerenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onPointerEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouchStart() !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onMouseDown() !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Tab" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onTabKeyDown() !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 245760, null, 0, i13.MainComponent, [i12.DOCUMENT, i1.LOCALE_ID, i1.PLATFORM_ID, i9.ModalContactService, i6.ActivatedRoute, i6.Router, i14.UrlService, i15.RouterExtensionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hoverEnabled; var currVal_1 = i1.ɵnov(_v, 1).hoverDisabled; var currVal_2 = i1.ɵnov(_v, 1).focusOutlineDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var MainComponentNgFactory = i1.ɵccf("app-main", i13.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
