import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { atsAppDomain } from '@env/environment';
import { Store } from '@ngxs/store';
import { RecruitersState } from '@store/recruiters/recruiters.state';
import { SessionState } from '@store/session/session.state';
import { WINDOW } from '@wizbii/angular-utilities';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MustHaveRecruiterGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    @Inject(ErrorHandler) private readonly errorHandlerService: ErrorHandlerService,
    @Inject(WINDOW) private readonly window: any
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter(isInitialized => isInitialized),
      first(),
      switchMap(() =>
        combineLatest([
          this.store.selectOnce(RecruitersState.authenticatedRecruiterId),
          this.store.selectOnce(SessionState.tokens),
        ])
      ),
      map(([hasRecruiter, tokens]) => {
        if (!hasRecruiter && !!tokens) {
          this.errorHandlerService.warn('Finish your onbording first');

          this.window.location.assign(`https://${atsAppDomain}/wizbii/sign-in`);
          return false;
        }

        return true;
      })
    );
  }
}
