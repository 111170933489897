import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Company, Recruiter } from '@ats/models';
import { atsAppDomain } from '@env/environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { WINDOW } from '@wizbii/angular-utilities';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sticky-menu',
  templateUrl: './sticky-menu.component.html',
  styleUrls: ['./sticky-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() recruiter: Recruiter;
  @Input() company: Company;
  @Input() isLogged: boolean;
  @Input() logoColorOverride: string;

  homeUrl: string;
  menuOpen: boolean;
  pos: number;
  windowWidth: number;

  readonly destroyed$ = new Subject<void>();

  appUrl = `https://${atsAppDomain}`;
  featuresRoutingEnum = FeaturesRoutingEnum;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(WINDOW) private readonly window: any,
    readonly router: Router
  ) {
    this.homeUrl = '/';
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    if (event.target.innerWidth > 931) {
      this.menuOpen = false;
    }

    this.cdr.detectChanges();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    this.pos = this.window.pageYOffset;
    this.cdr.detectChanges();
  }

  toggleMenu(e: boolean) {
    this.menuOpen = e;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.windowWidth = this.window.innerWidth;
    this.cdr.detectChanges();
  }
}
