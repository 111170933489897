var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { Select } from '@ngxs/store';
import { CompaniesState } from '@store/companies/companies.state';
import { RecruitersState } from '@store/recruiters/recruiters.state';
import { SessionState } from '@store/session/session.state';
import { Observable } from 'rxjs';
var NotFoundComponent = /** @class */ (function () {
    function NotFoundComponent(route, routerExtension, response) {
        this.route = route;
        this.routerExtension = routerExtension;
        this.response = response;
    }
    NotFoundComponent.prototype.ngOnInit = function () {
        if (this.response) {
            this.response.statusCode = 404;
            this.response.statusMessage = 'Not Found';
        }
        else if (this.route.snapshot.data.unhandled404 && this.routerExtension.previousUrl) {
            throw new Error('Unhandled 404');
        }
    };
    __decorate([
        Select(RecruitersState.authenticatedRecruiter),
        __metadata("design:type", Observable)
    ], NotFoundComponent.prototype, "recruiter$", void 0);
    __decorate([
        Select(CompaniesState.authenticatedCompany),
        __metadata("design:type", Observable)
    ], NotFoundComponent.prototype, "company$", void 0);
    __decorate([
        Select(SessionState.isLogged),
        __metadata("design:type", Observable)
    ], NotFoundComponent.prototype, "isLogged$", void 0);
    return NotFoundComponent;
}());
export { NotFoundComponent };
