import { ReplaySubject } from 'rxjs';
var defaultConfig = { layered: false, type: 'contact' };
var ModalContactService = /** @class */ (function () {
    function ModalContactService() {
        this.display = new ReplaySubject();
    }
    Object.defineProperty(ModalContactService.prototype, "display$", {
        get: function () {
            return this.display.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalContactService.prototype, "config", {
        get: function () {
            return this.modal.config;
        },
        enumerable: true,
        configurable: true
    });
    ModalContactService.prototype.open = function (display, onDismiss, config) {
        if (display === void 0) { display = ''; }
        if (config === void 0) { config = defaultConfig; }
        this.modal = { onDismiss: onDismiss, config: config };
        this.display.next(display);
        return this.close.bind(this);
    };
    ModalContactService.prototype.dismiss = function () {
        if (this.modal && this.modal.onDismiss) {
            this.modal.onDismiss();
        }
    };
    ModalContactService.prototype.close = function () {
        this.modal = undefined;
        this.display.next();
    };
    return ModalContactService;
}());
export { ModalContactService };
