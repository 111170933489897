import { HttpErrorResponse } from '@angular/common/http';
import { Company } from '@ats/models';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetCompany, GetCompanySuccess, SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { CompanyWebservice } from '@wizbii/webservices';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

export class CompaniesStateModel {
  authenticatedCompanyId: string;
  companies: Record<string, Company>;

  error: any;
}

const defaultState: CompaniesStateModel = {
  authenticatedCompanyId: null,
  companies: {},

  error: null,
};

@State<CompaniesStateModel>({
  name: 'companies',
  defaults: defaultState,
})
export class CompaniesState {
  static company(companyId: string) {
    return createSelector(
      [CompaniesState],
      (state: CompaniesStateModel) => {
        return state.companies[companyId];
      }
    );
  }

  @Selector()
  static authenticatedCompany(state: CompaniesStateModel) {
    return state.companies[state.authenticatedCompanyId];
  }

  constructor(private readonly companyWebservice: CompanyWebservice) {}

  @Action(GetCompany)
  getCompany(ctx: StateContext<CompaniesStateModel>, { id, silence404 }: GetCompany) {
    const { companies } = ctx.getState();

    if (!companies[id]) {
      return this.companyWebservice.get(id).pipe(
        switchMap(company => ctx.dispatch(new GetCompanySuccess(company))),
        silence404 // ADR #006
          ? catchError((err: HttpErrorResponse | Error) => {
              ErrorHandlerService.silence(err, err instanceof HttpErrorResponse && err.status === 404);
              return throwError(err);
            })
          : tap(() => {})
      );
    }
  }

  @Action(GetCompanySuccess)
  getCompanySuccess(ctx: StateContext<CompaniesStateModel>, { company }: GetCompanySuccess) {
    return ctx.setState(
      patch<CompaniesStateModel>({
        companies: patch({
          [company._id]: company,
        }),
      })
    );
  }

  @Action(SetAuthenticatedCompany)
  setAuthenticatedCompany(ctx: StateContext<CompaniesStateModel>, { id }: SetAuthenticatedCompany) {
    ctx.patchState({ authenticatedCompanyId: id });
    return ctx.dispatch(new GetCompany(id, false));
  }
}
