import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, PLATFORM_ID } from '@angular/core';
import { GlobalService } from '@core/services/global.service';
import { UserOverview } from '@models/auth/user-overview.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetAuthenticatedRecruiter } from '@store/recruiters/recruiters.actions';
import { Init, InitUser, Logout, RefreshTokensStore, SetTokens } from '@store/session/session.actions';
import { DataStorageService, deserializeJwt, JwtTokens } from '@wizbii/angular-utilities';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export enum SessionStateEnum {
  Init = 'INIT',
  Logged = 'LOGGED',
  Recovered = 'RECOVERED',
  NotLogged = 'NOT_LOGGED',
}

export class SessionStateModel {
  tokens: JwtTokens;
  loading: boolean;
  state: SessionStateEnum;
  error: HttpErrorResponse | Error;
  errorMessage: string;
  user: UserOverview;
}

const defaultState: SessionStateModel = {
  tokens: null,
  loading: false,
  state: SessionStateEnum.Init,
  error: null,
  errorMessage: null,
  user: null,
};

@State<SessionStateModel>({
  name: 'session',
  defaults: defaultState,
})
export class SessionState {
  // tslint:disable:no-identical-functions

  @Selector()
  static tokens(state: SessionStateModel) {
    return state.tokens;
  }

  @Selector()
  static isInitialized(state: SessionStateModel) {
    return state.state !== SessionStateEnum.Init;
  }

  @Selector()
  static isLogged(state: SessionStateModel) {
    return state.state === SessionStateEnum.Logged;
  }

  constructor(
    private readonly dataStorageService: DataStorageService,
    private readonly authService: AuthenticationWebservice,
    private readonly cookieService: CookieService,
    private readonly globalService: GlobalService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(DOCUMENT) private readonly document: any
  ) {}

  @Action(Init)
  init(ctx: StateContext<SessionStateModel>, { tokens }: Init) {
    const realTokens = tokens ? tokens : this.readTokens();

    if (!!realTokens) {
      this.globalService.init(realTokens.token, realTokens.refreshToken);
      ctx.patchState({ tokens: realTokens });

      const userId = deserializeJwt(realTokens.token)['user-id'];

      return ctx.dispatch([new InitUser(userId), new SetAuthenticatedRecruiter(userId)]).pipe(
        switchMap(() => of(ctx.patchState({ state: SessionStateEnum.Logged }))),
        catchError(() => of(ctx.patchState({ state: SessionStateEnum.NotLogged })))
      );
    }

    return ctx.patchState({ state: SessionStateEnum.NotLogged });
  }

  @Action(InitUser)
  initUser(ctx: StateContext<SessionStateModel>, { id }: InitUser) {
    return this.authService.getUserOverview(id).pipe(switchMap(user => of(ctx.patchState({ user }))));
  }

  @Action(RefreshTokensStore)
  refreshTokensStore(ctx: StateContext<SessionStateModel>, action: RefreshTokensStore) {
    const { tokens } = action;

    return ctx.patchState({
      tokens,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<SessionStateModel>) {
    this.forgetTokens();

    if (isPlatformBrowser(this.platformId)) {
      this.document.location = this.document.location.origin;
    }

    return ctx.setState(defaultState);
  }

  @Action(SetTokens)
  setTokens(ctx: StateContext<SessionStateModel>, action: SetTokens) {
    const { tokens } = action;

    this.writeTokens(tokens);

    return ctx.patchState({
      tokens,
    });
  }

  private readTokens(): JwtTokens | null {
    const rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');

    return !!rawTokens ? rawTokens : null;
  }

  private writeTokens(tokens: JwtTokens) {
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(GlobalService.EXPIRY_KEY);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(GlobalService.EXPIRY_KEY))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        GlobalService.EXPIRY_KEY,
        expiry.getTime().toString(),
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      GlobalService.TOKEN_KEY,
      JSON.stringify(tokens),
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private forgetTokens() {
    const cookieDomain = this.getCookieDomain();

    this.dataStorageService.remove(GlobalService.TOKEN_KEY);
    this.cookieService.set(
      GlobalService.TOKEN_KEY,
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );

    this.cookieService.set(
      GlobalService.EXPIRY_KEY,
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }
}
