<main class="not-found">
  <span class="container__background"></span>

  <app-sticky-menu
    [company]="company$ | async"
    [isLogged]="isLogged$ | async"
    [recruiter]="recruiter$ | async"
    logoColorOverride="#000028"
  ></app-sticky-menu>

  <div class="not-found__content">
    <header class="content__right">
      <h1 class="title">404</h1>
      <h2 class="sub-title" i18n>Page introuvable</h2>
    </header>

    <section class="content__left description">
      <header>
        <h3 class="description__title" i18n>Bonjour,</h3>
      </header>

      <p class="description__text">
        <ng-container i18n>
          Malgré votre profil et vos compétences, nous sommes dans le regret de vous annoncer
        </ng-container>
        <strong i18n>que cette page n'existe plus. </strong>
        <ng-container i18n>Nous vous conseillons de retourner à la page précédente.</ng-container>
        <br /><br />
        <ng-container i18n>Bonne continuation dans votre navigation.</ng-container>
        <br /><br />
        <ng-container i18n>Cordialement.</ng-container>
      </p>

      <footer>
        <a class="back-btn btn btn--shadow" [routerLink]="['/']">Retour</a>
      </footer>
    </section>
  </div>

  <app-footer></app-footer>
</main>
