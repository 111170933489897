import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InitGuard } from '@core/guards/init.guard';
import { MustHaveRecruiterGuard } from '@core/guards/must-have-recruiter.guard';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';

const routes: Routes = [
  {
    path: FeaturesRoutingEnum.Home,
    pathMatch: 'full',
    canActivate: [InitGuard, MustHaveRecruiterGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: FeaturesRoutingEnum.Feature,
    canActivate: [InitGuard, MustHaveRecruiterGuard],
    loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule),
  },
  {
    path: FeaturesRoutingEnum.Pricing,
    canActivate: [InitGuard, MustHaveRecruiterGuard],
    loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule),
  },
  {
    path: '',
    canActivate: [InitGuard, MustHaveRecruiterGuard],
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule),
  },
  {
    path: FeaturesRoutingEnum.NotFound,
    canActivate: [InitGuard, MustHaveRecruiterGuard],
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { unhandled404: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
