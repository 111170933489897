import { Directive, HostListener, Input } from '@angular/core';
import { AlgoliaTrackerInterface, EventTrackerInterface } from '@commons/tracker/event-tracker/event-tracker.interface';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';

@Directive({
  selector: '[appEventTracker][eventCategory][eventAction]',
})
export class EventTrackerDirective {
  model: EventTrackerInterface = {
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    eventValue: undefined,
    algolia: undefined,
  };

  constructor(private readonly eventWebservice: EventTrackerService) {}

  @Input()
  set eventCategory(eventCategory: string) {
    this.model.eventCategory = eventCategory;
  }

  @Input()
  set eventAction(eventAction: string) {
    this.model.eventAction = eventAction;
  }

  @Input()
  set eventLabel(eventLabel: string) {
    this.model.eventLabel = eventLabel;
  }

  @Input()
  set eventValue(eventValue: string | number | boolean) {
    this.model.eventValue = eventValue;
  }

  @Input()
  set algoliaTrackingData(algolia: AlgoliaTrackerInterface) {
    this.model.algolia = algolia;
  }

  @HostListener('click')
  doClick(): void {
    this.eventWebservice.send({
      ...this.model,
      algolia: this.model.algolia && this.model.algolia.queryId ? this.model.algolia : undefined,
    });
  }
}
